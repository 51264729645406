<template>
  <div class="customer_block">
    <div class="logo">
      <img
        v-if="person"
        :src="customer.photo"
        @load="onImgLoad"
        @error="imgUrlWrong"
        class="photo"
        alt="photo">
      <img
        v-else
        :src="customer.logo"
        @load="onImgLoad"
        @error="imgUrlWrong"
        alt="logo">
      <a href="#" @click.prevent="null">EDIT PROFILE</a>
    </div>
    <div class="content">
      <div class="customer_main">
        <div
          v-if="person"
          class="info"
        >
          <span v-if="person">{{ customer.first_name }} {{ customer.last_name }}</span>
          <span v-else>{{ customer.name }}</span>
          <p class="person">{{ customer.role }}</p>
          <p class="person">{{ customer.email }}</p>
        </div>
        <div
          v-else
          class="info"
        >
          <span>{{ customer.name }}</span>
          <p>
            Amazon (Amazon.com) is the world's largest online retailer
            and a prominent cloud services provider. ... Amazon is headquartered
            in Seattle, Washington. The company has individual websites, software
            development centers, customer service centers and fulfillment centers
            in many locations around the world.
          </p>
        </div>
        <div class="contact">
          <span>CONTACTS</span>
          <ul>
            <li>
              <img src="@/assets/contact_mail.svg" alt="logo">
              <a href="#" @click.prevent="null">valeria_klinton@amazon.com</a>
            </li>

            <li>
              <img src="@/assets/contact_link.svg" alt="logo">
              <a href="#" @click.prevent="null">https://www.linkedin.com/in/valeriaklinton/</a>
            </li>

            <li>
              <img src="@/assets/contact_hub.svg" alt="logo">
              <a href="#" @click.prevent="null">https://www.hubspot.com/in/valeriaklinton/</a>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="!person" class="stages">
        <img src="@/assets/stages_holder.png" alt="stages">
      </div>

      <div v-else class="last_activity">
        <div class="activity_container">
          <div class="activity_head">
            <div class="left">
              <img src="@/assets/contact_mail.svg" alt="date">
              <span>{{ last_activity.date }}</span>
            </div>
            <div class="right">
              LAST ACTIVITY
            </div>
          </div>
          <p class="activity_content theme">{{ last_activity.theme }}</p>
          <p class="activity_content">{{ last_activity.content }}</p>
        </div>
      </div>

      <div class="notes_wrapper">
        <div class="notes_header">
          <span>
            NOTES &bull; <span>{{ notes_list.length }}</span>
          </span>
          <a v-if="notes_list.length > 1" href="#" @click.prevent="showNotes({ notes: notes_list, customer: customer })">
            VIEW ALL NOTES
          </a>
        </div>
        <div
          v-if="notes_list.length > 0"
          class="notes_list_wrapper"
        >
          <Note :note="notes_list[notes_list.length - 1]" />
        </div>
        <div class="notes_set">
          <img src="@/assets/profile.png" alt="photo" />
          <input
            v-model="note"
            type="text"
            placeholder="Write new note"
            v-on:keyup.enter="apply_notes"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  ADD_NOTE,
  FETCH_NOTES,
  SHOW_NOTES
} from '@/store/actions.type'

import Note from '@/components/profile/Note.vue'

export default {
  name: 'CustomerBlock',
  components: {
    Note
  },
  props: {
    customer: Object,
    person: Boolean
  },
  data () {
    return {
      note: '',
      notes_all: false,
      notes_list: [],
      last_activity: {
        id: 1,
        date: 'Today · 9:10 AM',
        name: 'Alice Cooper',
        theme: 'Re: Sales',
        content: 'Nice!... should show the change in price also with the different customization.'
      }
    }
  },
  created () {
    // if (!this.person) {
    this.fetch_notes()
    // }
  },
  methods: {
    ...mapActions({
      addNote: ADD_NOTE,
      fetchNotes: FETCH_NOTES,
      showNotes: SHOW_NOTES
    }),
    fetch_notes () {
      this.fetchNotes({
        id: this.customer.id,
        kind: this.person ? 'contact' : 'customer'
      }).then(({ data }) => {
        this.notes_list = data
      })
    },
    apply_notes () {
      const noteObject = {
        content: this.note,
        id: this.customer.id,
        kind: this.person ? 'contact' : 'customer'
      }
      if (this.note !== '') {
        this.addNote(noteObject).then((d) => {
          this.note = ''
          this.fetch_notes()
        })
      }
    },
    imgUrlWrong (event) {
      event.target.style.display = 'none'
    },
    onImgLoad (event) {
      event.target.classList.add('loaded')
    }
  }
}
</script>

<style scoped>
.customer_block {
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 25px;
}
.customer_block .logo {
  width: 250px;
}
.customer_block .logo img.loaded {
  object-fit: cover;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 10px;
  width: 160px;
  height: 160px;
  margin: 30px 45px 0;
}
.customer_block .logo img.loaded.photo {
  border-radius: 90px;
}
.customer_block .logo a {
  width: 160px;
  height: 50px;
  border: 1px solid #1CB1B8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px;
  display: block;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  text-decoration: none;

  color: #1CB1B8;
  background: #fff;
  margin: 30px auto 0;
  /* opacity: 0.8; */
  transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.customer_block .logo a:hover {
  color: #fff;
  background: #1CB1B8;
}
.customer_block .content {
  padding: 30px 45px 0 0;
  width: 100%;
}
.customer_block .content .stages {
  margin: 0 0 45px 0;
}

/** --- */

.customer_block .content .notes_wrapper {}

.customer_block .content .notes_wrapper .notes_set {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
  padding-bottom: 65px;
}
.customer_block .content .notes_wrapper .notes_set input {
  width: 100%;
  height: 46px;
  background: #F7F7F7;
  border-radius: 0px 24px 24px 24px;
  border: 0;
  padding: 12px 20px;
  display: block;
  box-sizing: border-box;
  outline: 0;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
}
.customer_block .content .notes_wrapper .notes_set img {
  width: 32px;
  height: 32px;
  box-shadow: inset 0px 0px 7px rgba(0, 0, 0, 0.85);
  border-radius: 50%;
  display: block;
  margin: 0 5px 0 0;
}
.customer_block .content .notes_wrapper .notes_header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
}
.customer_block .content .notes_wrapper .notes_header a,
.customer_block .content .notes_wrapper .notes_header > span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #858585;
  display: inline-block;
  text-decoration: none;
  /* opacity: 0.8; */
}
.customer_block .content .notes_wrapper .notes_header span span {
  font-weight: 700;
}
.customer_block .content .notes_wrapper .notes_header a {
  color: #1CB1B8;
}
.customer_block .content .notes_wrapper .notes_header a:hover {
  text-decoration: underline;
}

.customer_block .content .last_activity,
.customer_block .content .notes_wrapper .notes_list_wrapper {
  margin: 0 0 35px 0;
}
.customer_block .content .last_activity .activity_container,
.customer_block .content .notes_wrapper .notes_list_wrapper .note_container {
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
}
.customer_block .content .last_activity .activity_container .activity_content,
.customer_block .content .notes_wrapper .notes_list_wrapper .note_container .note_content {
  margin: 0 30px 30px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
}
.customer_block .content .last_activity .activity_container .activity_content.theme {
  font-weight: 700;
  margin-bottom: 10px;
}

.customer_block .content .last_activity .activity_container .activity_head,
.customer_block .content .notes_wrapper .notes_list_wrapper .note_container .note_head {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 10px 30px;
}
.customer_block .content .notes_wrapper .notes_list_wrapper .note_container .note_head .right img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 0 0 15px;
  padding: 0;
  display: block;
}
.customer_block .content .notes_wrapper .notes_list_wrapper .note_container .note_head .right span {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #333333;
}
.customer_block .content .last_activity .activity_container .activity_head .right {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #858585;
}
.customer_block .content .notes_wrapper .notes_list_wrapper .note_container .note_head .right,
.customer_block .content .last_activity .activity_container .activity_head .left,
.customer_block .content .notes_wrapper .notes_list_wrapper .note_container .note_head .left {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.customer_block .content .last_activity .activity_container .activity_head .left img,
.customer_block .content .notes_wrapper .notes_list_wrapper .note_container .note_head .left img {
  margin: 0 10px 0 0;
  padding: 0;

}
.customer_block .content .last_activity .activity_container .activity_head .left span,
.customer_block .content .notes_wrapper .notes_list_wrapper .note_container .note_head .left span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #858585;
}

/** --- */

.customer_block .content .customer_main {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 30px;
}
.customer_block .content .customer_main .info {
  width: 50%;
  padding: 0 120px 0 0;
  display: block;
  box-sizing: border-box;
}
.customer_block .content .customer_main .info span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.05em;
  color: #343333;
  margin: 0 0 5px;
  display: block;
}
.customer_block .content .customer_main .info p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #858585;
  margin: 0;
}
.customer_block .content .customer_main .info p.person {
  font-size: 16px;
  line-height: 24px;
}
.customer_block .content .customer_main .contact {
  width: 50%;
  padding: 0;
  display: block;
  box-sizing: border-box;
}
.customer_block .content .customer_main .contact span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #858585;
  padding: 5px 0 0 0;
  margin: 0 0 15px 0;
  display: block;
}
.customer_block .content .customer_main .contact ul {
  margin: 0;
  padding: 0;
}
.customer_block .content .customer_main .contact ul li {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.customer_block .content .customer_main .contact ul li img {
  margin: 0 10px 0 0;
  display: block;
}
.customer_block .content .customer_main .contact ul li a {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #858585;
  text-decoration: none;
}
.customer_block .content .customer_main .contact ul li a:hover {
  text-decoration: underline;
}
</style>
