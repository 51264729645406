<template>
  <div class="message search_active">
    <img
      v-if="kind !== 'salesforce'"
      :class="['icon', kind, message.item_type]"
      :src="item_type_logo[message.item_type]"
      alt="icon"
    >
    <div class="header">
      <div :class="['customer', kind]">
        <img
          v-if="author && author.photo && kind !== 'salesforce'"
          :src="author.photo"
          @load="onImgLoad"
          @error="imgUrlWrong"
          alt="Photo"
        >
        <img
          v-if="activity_customer && activity_customer.logo && kind === 'salesforce'"
          :src="activity_customer.logo"
          @load="onImgLoad"
          @error="imgUrlWrong"
          alt="Photo"
        >
        <div
          v-if="author && kind !== 'salesforce'"
          class="description"
        >
          <span class="name">{{ full_name }}</span>
          <span class="position">{{ author.role }}</span>
        </div>
         <div
          v-if="activity_customer && kind === 'salesforce'"
          :class="['description', kind]"
        >
          <span class="name">{{ activity_customer.name }}</span>
        </div>
      </div>
      <div class="thread">
        <img
          :data-test="message.nested_type"
          :src="thread_logo[message.nested_type]"
          alt="Logo"
        >
        <span>{{ date }}</span>
      </div>
    </div>
    <div :class="['wrapper', message.item_type]">
      <div
        v-if="message.item_type === 'post'"
        class="content"
      >
        <h3>{{ message.title }}</h3>
        <img
          v-if="message.image"
          :src="message.image"
          @load="onImgLoad"
          @error="imgUrlWrong"
          alt="image">
        <p class="post_content" v-html="message_body"></p>
        <!-- <a href="#" @click.prevent="null">Read more</a> -->
      </div>
      <div
        v-else
        class="content"
      >
        <div
          v-if="message.item_type === 'gmail'"
          :class="[message.item_type]"
        >
          <h3
            v-if="message.subject !== ''"
            class="suject"
          >
            Subject: <span>{{ message.subject }}</span>
          </h3>
          <p :class="[{ short: message.body.length > symbols_for_short }, 'body']" v-html="message_body" />
          <span
            v-if="message.replies.length > 0"
            class="replies"
          >
            Replies: {{ message.replies.length }}
          </span>
        </div>
        <p
          v-if="message.item_type === 'share'"
          :class="[message.item_type]"
          v-html="message_body"
        ></p>
        <p
          v-if="message.item_type === 'salesforce'"
          :class="[message.item_type]"
          v-html="salesforce_entries()"
        ></p>
        <div
          v-if="single_post.id"
          class="small_post"
        >
          <img
            v-if="single_post.image"
            :src="single_post.image"
            @load="onImgLoad"
            @error="imgUrlWrong"
            alt="image">
          <div class="description">
            <span v-if="message.item_type === 'comment'">Commented to Post:</span>
            <span v-if="message.item_type === 'like'">Liked Post:</span>
            <span v-if="message.item_type === 'share'">Shared Post:</span>
            <a href="#" @click.prevent="null">{{ single_post.title }}</a>
          </div>
        </div>
        <p
          v-if="message.item_type === 'comment'"
          :class="[message.item_type]"
          v-html="message_body"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  FETCH_SINGLE_POST
} from '@/store/actions.type'

export default {
  name: 'Singl Message',
  props: {
    message: Object,
    kind: String
  },
  created () {
    // console.log('SM - ', this.message)
    let val = 'text'
    switch (this.message.item_type) {
      case 'salesforce':
        val = 'stage'
        break
      case 'gmail':
        val = 'body'
        break
      case 'comment':
        val = 'text'
        break
      case 'post':
        val = 'text'
        break
      default:
        val = ''
        break
    }
    if (this.message[val]) {
      this.message_body = this.message[val].replaceAll('\n', '<br>')
      if (this.activity_seach_query) {
        const queryLc = this.activity_seach_query.toLowerCase()
        const bodyLc = this.message[val].toLowerCase()
        const parts = bodyLc.split(queryLc)
        let index = 0
        let result = ''
        parts.forEach((part, idx) => {
          const subStr = this.message[val].substring(index, index + part.length)
          result += subStr
          index += part.length
          if (idx !== parts.length - 1) {
            const queryOriginal = this.message[val].substring(index, index + queryLc.length)
            result += `<span style="background-color: #e4ff0066;" class="search_active">${queryOriginal}</span>`
          }
          index += queryLc.length
        })
        this.message_body = result.replaceAll('\n', '<br>')
      }
    }
    if (this.message.source_type === 'team' || this.message.source_type === 'salesforce') {
      this.author = this.authorsList.find(cont => {
        if (this.message.item_type === 'gmail') {
          return cont.id === this.message.sender
        } else {
          return cont.id === this.message.author
        }
      })
      if (!['post', 'gmail', 'salesforce'].includes(this.message.item_type)) {
        this.single_post = this.postsList.find(post => {
          return post.id === this.message.post
        })
      }
    } else {
      if (this.activity_customer.contacts) {
        this.author = this.activity_customer.contacts.find(cont => {
          if (this.message.item_type === 'gmail') {
            return cont.id === this.message.sender
          } else {
            return cont.id === this.message.author
          }
        })
      }

      if (!['post', 'gmail', 'salesforce'].includes(this.message.item_type)) {
        this.fetchPost(this.message.post).then(({ data }) => {
          this.single_post = data
        })
      }
    }
    // console.log('message', this.kind, this.message, this.author)
  },
  computed: {
    ...mapGetters([
      'activity_customer',
      'activity_seach_query'
    ]),
    date () {
      // if (this.message.item_type === 'salesforce') {
      //   return new Date(this.message.close_date).toLocaleTimeString()
      // } else {
      if (this.message.item_type === 'gmail') {
        return new Date(this.message.sent).toLocaleTimeString()
      } else {
        if (this.message.item_type === 'salesforce') {
          return new Date(this.message.created).toLocaleDateString()
        } else {
          return new Date(this.message.created).toLocaleTimeString()
        }
      }
      // }
    },
    full_name () {
      return `${this.author.first_name} ${this.author.last_name}`
    },
    body () {
      return this.message.body.replaceAll('\n', '<br>')
    }
  },
  data () {
    return {
      message_body: '',
      item_type_logo: {
        like: require('@/assets/icons/type_likes.svg'),
        comment: require('@/assets/icons/type_comments.svg'),
        post: require('@/assets/icons/type_posts.svg'),
        share: require('@/assets/icons/type_shares.svg'),
        gmail: require('@/assets/icons/type_emails.svg'),
        salesforce: ''
      },
      thread_logo: {
        linkedin: require('@/assets/contact_link.svg'),
        gmail: require('@/assets/icons/mail.svg'),
        salesforce: require('@/assets/icons/salesforce.svg'),
        Account: require('@/assets/icons/salesforce.svg'),
        Lead: require('@/assets/icons/salesforce.svg'),
        Opportunity: require('@/assets/icons/salesforce.svg'),
        Contact: require('@/assets/icons/salesforce.svg')
      },
      author: {},
      authorsList: [{
        id: 111,
        first_name: 'Larry',
        last_name: 'Brown',
        role: 'Team Member'
      }, {
        id: 222,
        first_name: 'Maria',
        last_name: 'Waltz',
        role: 'Manager'
      }, {
        id: 333,
        first_name: 'Dan',
        last_name: 'Gomez',
        role: 'Team Member'
      }, {
        id: 444,
        first_name: 'Enrique',
        last_name: 'Lindergerg',
        role: 'Team Member'
      }, {
        id: 911,
        first_name: 'Daniel',
        last_name: 'Roy',
        role: 'Team Member'
      }, {
        id: 811,
        first_name: 'Maria',
        last_name: 'Schmidt',
        role: 'Team Member'
      }],
      postsList: [{
        id: 5555,
        image: require('@/assets/test/post1.jpg'),
        title: 'It was great to finally be able...'
      }, {
        id: 6666,
        image: '',
        title: 'As a founder, you may quickly discover...'
      }, {
        id: 7777,
        image: require('@/assets/test/post2.png'),
        title: 'Smart Energy 2022'
      }],
      single_post: {},
      symbols_for_short: 100
    }
  },
  methods: {
    ...mapActions({
      fetchPost: FETCH_SINGLE_POST
    }),
    imgUrlWrong (event) {
      event.target.style.display = 'none'
    },
    onImgLoad (event) {
      event.target.classList.add('loaded')
    },
    salesforce_entries () {
      const skipVals = ['item_type', 'source_type', 'nested_type', 'id', 'created', 'last_modified', 'salesforce_id']
      let body = `${this.message.nested_type}<br>`
      for (const [key, value] of Object.entries(this.message)) {
        if (!skipVals.includes(key) && value !== null) {
          let k = ''
          const kw = key.split('_')
          kw.forEach((w) => {
            k += w.charAt(0).toUpperCase() + w.slice(1) + ' '
          })
          body += `${k.trim()}: ${value}`
          body += '<br>'
        }
      }
      return body
    }
  }
}
</script>

<style scoped>
.message {
  width: 400px;
  padding: 25px 25px 20px 32px;
  margin: 0 0 15px 5px;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
}
.message p span.search_active{
  background: red !important;
}
.message .wrapper {}
.message .wrapper .content {}

/** for POSTs */
.message .wrapper.post .content a {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #1CB1B8;
}
.message .wrapper.post .content p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
}
.message .wrapper.post .content a:hover {
  text-decoration-line: none;
}

.message .wrapper.post .content h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  margin: 0 0 5px;
}
.message .wrapper.post .content img {
  width: 0px;
  height: 0px;
  display: none;
}
.message .wrapper .content p.salesforce::first-letter {
    text-transform:capitalize;
}
.message .wrapper.post .content img.loaded {
  width: 340px;
  height: 180px;
  object-fit: cover;
  display: block;
  margin: 0 0 5px;
}
/** single post */
.message .wrapper .content .small_post {
  margin: 0 0 5px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.message .wrapper .content .small_post .description {
  font-family: Poppins;
  font-style: normal;
  letter-spacing: 0.05em;
  width: calc(100% - 85px);
}
.message .wrapper .content .small_post .description span {
  display: block;
  font-weight: 300;
  font-size: 10px;
  line-height: 16px;
  color: #858585;
}
.message .wrapper .content .small_post .description a {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #1CB1B8;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.message .wrapper .content .small_post .description a:hover {
  text-decoration-line: none;
}
.message .wrapper .content .small_post img {
  width: 0px;
  height: 0px;
  display: none;
}
.message .wrapper .content .small_post img.loaded {
  width: 75px;
  height: 40px;
  object-fit: cover;
  display: block;
  margin: 0 10px 0 0;
  padding: 0;
}

/** for COMMENTs & SHAREs */
.message .wrapper.share .content p,
.message .wrapper.salesforce .content p,
.message .wrapper.comment .content p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
}
/** --- */

.message .header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 15px;
}
.message img.icon {
  object-fit: none;
  width: 30px;
  height: 30px;
  display: block;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  top: -5px;
  left: -5px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.message img.icon.like {
  padding-bottom: 3px;
}
.message .header .customer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 75%;
}
.message .header .customer.salesforce {
  align-items: center;
}
.message .header .customer img {
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  margin-right: 10px;
}
.message .header .customer .description span {
  font-family: Poppins;
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  color: #333333;
  display: block;
  margin: 0;
  padding: 0;
}
.message .header .customer .description .name {
  font-weight: bold;
}
.message .header .customer .description.salesforce .name {
  font-size: 18px;
}
.message .header .customer .description .position {
  font-weight: normal;
}
.message .header .thread {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}
.message .header .thread img {
  width: 30px;
  height: 30px;
}
.message .header .thread span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #858585;
}
.message .wrapper.gmail .content h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  margin: 0 0 5px;
}
.message .wrapper.gmail .content h3 span {
  font-weight: bold;
}
.message .wrapper.gmail .content p.body {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #333333;

  /* width: calc(100% - 85px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
}
.message .wrapper.gmail .content span.replies {
  font-family: Poppins;
  font-style: normal;
  letter-spacing: 0.05em;
  display: block;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #333;
}
</style>
