<template>
  <div class="timeline" :key="`${activity_customer.id}-${Math.random()}`">
    <div class="navigation">
      <nav class="top">
        <h1 class="header">
          <b><span>{{ activity_customer.name }}</span>'s</b> ACTIVITY
        </h1>
      </nav>
      <nav class="bottom">
        <Search />
      </nav>
    </div>
    <div
      v-if="activity_customer.ready"
      class="content"
      ref="contentbox"
    >
      <div class="separator" />
      <div class="months_wrapper">
        <template
          v-for="year in activity_customer.active_years"
        >
          <TimeLineNavigation
            v-for="month in activity_customer.active_months"
            :key="`${year}${month.id}-month-${Math.random()}`"
            :year="year"
            :type="active_type"
            :month="month"
          />
        </template>
        <div v-if="!activity_customer.active_months.length" class="empty" />
      </div>
      <div class="lines_wrapper">
        <TimeLineActivityLine v-once :kind="'team'" :key="'team'" @active="change_active" />
        <TimeLineActivityLine v-once :kind="'linkedin'" :key="'linkedin'" @active="change_active" />
        <TimeLineActivityLine v-once :kind="'salesforce'" :key="'salesforce'" @active="change_active" />
        <!-- <TimeLineActivityLine v-once :kind="'twitter'" :key="'twitter'" @active="change_active" /> -->
        <!-- <TimeLineActivityLine v-once :kind="'сhorus'" :key="'сhorus'" @active="change_active" /> -->
        <TimeLineActivityLine v-once :kind="'gmail'" :key="'gmail'" @active="change_active" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  UPDATE_ACTIVITY_SHOWING_MESSAGES,
  UPDATE_ACTIVE_LINE,
  FETCH_CUSTOMER_ACTIVITY,
  UPDATE_ACTIVE_TYPE,
  UPDATE_ACTIVE_POINT
} from '@/store/actions.type'

// import CustomersList from '@/components/activity/side/CustomersList.vue'
import Search from '@/components/activity/side/Search.vue'
import TimeLineNavigation from '@/components/activity/activity_components/TimeLineNavigation.vue'
import TimeLineActivityLine from '@/components/activity/activity_components/TimeLineActivityLine.vue'

export default {
  name: 'Activity TimeLine',
  components: {
    // CustomersList,
    Search,
    TimeLineNavigation,
    TimeLineActivityLine
  },
  computed: {
    ...mapGetters([
      'activity_showing_messages',
      'activity_customer_id',
      'activity_customer',
      'activity_scroll_to',
      'active_type',
      'active_line',
      'months',
      'config'
    ])
  },
  watch: {
    activity_customer () {
      this.setActiveType(this.type)
    },
    active_type (val) {
      const today = new Date()
      let type = 'day'
      switch (val.date_range) {
        case '14_day':
          today.setDate(today.getDate() - 14)
          type = 'day'
          break
        case '1_mon':
          today.setMonth(today.getMonth() - 1)
          type = 'month'
          break
        case '3_mon':
          today.setMonth(today.getMonth() - 3)
          type = 'month'
          break
        case '6_mon':
          today.setMonth(today.getMonth() - 6)
          type = 'month'
          break
        case '1_year':
          today.setMonth(today.getMonth() - 12)
          type = 'month'
          break
        default:
          break
      }
      this.scroll_to = {
        type: type,
        date: today
      }
    }
  },
  created () {
    if (!this.activity_customer_id) {
      this.fetchCustomerActivity()
    }
  },
  updated () {
    if (this.scroll_to.type && this.scroll_to.date) {
      const presentDates = []
      document.querySelectorAll('[data-time]').forEach(elem => {
        const parts = elem.getAttribute('data-time').split('-')
        if (parts[0] === this.scroll_to.type) {
          presentDates.push(new Date(`${parts[1]}-${parts[2]}-${parts[3]}`))
        }
      })
      presentDates.sort((date1, date2) => date1 - date2)
      const date = presentDates.find(date => { return date >= this.scroll_to.date })
      if (date) {
        const qString = `${this.scroll_to.type}-${date.getFullYear()}-${date.getMonth() + 1}-${this.scroll_to.type === 'day' ? date.getDate() : 1}`
        const target = document.querySelector(`[data-time="${qString}"]`)
        if (target) {
          this.scroll_to = {}
          target.scrollIntoView()
        }
      }
    }
  },
  data () {
    return {
      customer_months: [],
      active: '',
      type: {
        kind: 'days',
        month_id: null,
        date_range: '14_day'
      },
      scroll_to: {}
    }
  },
  methods: {
    ...mapActions({
      clearShowingMessages: UPDATE_ACTIVITY_SHOWING_MESSAGES,
      fetchCustomerActivity: FETCH_CUSTOMER_ACTIVITY,
      updateActiveLine: UPDATE_ACTIVE_LINE,
      setActiveType: UPDATE_ACTIVE_TYPE,
      setPoint: UPDATE_ACTIVE_POINT
    }),
    change_active (kind) {
      const active = this.active_line === kind ? '' : kind
      this.updateActiveLine(active)
      this.clearShowingMessages({ date_range: '', messages: [], kind: this.active })
    }
  }
}
</script>

<style scoped>
  .timeline {
    max-width: 595px;
    width: 100%;
    padding: 0;
    align-self: stretch;
    box-sizing: border-box;
    height: calc(100vh - 82px);
  }
  .timeline .navigation {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .timeline .navigation .top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0 37px 50px;
  }
  .timeline .navigation .bottom {
    padding: 0 0 40px 50px;
  }
  .timeline .navigation h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.08em;

    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: 36px;
    color: #333333;
  }
  .timeline .navigation .top h1 span {
    text-transform: uppercase;
  }
  .timeline .navigation h1 .months {
    border: none;
  }

  .timeline .content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    /* height: 300px; */
    height: calc(100vh - 286px);
    overflow-y: scroll;
    box-sizing: border-box;
    -ms-overflow-style: none;  /*IE and Edge*/
    scrollbar-width: none;  /*Firefox*/
  }
  .timeline .content::-webkit-scrollbar {
    display: none;
  }

  .timeline .months_wrapper {
    box-sizing: border-box;
    position: relative;
  }
  /** + */
  .timeline .months_wrapper .empty {
    width: 184px;
  }
  .timeline .lines_wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 25px;
  }
  /** + */
  .timeline .separator {
    width: 1px;
    height: calc(100vh - 286px);
    border-left: 1px solid #c4c4c4;
    position: sticky;
    top: 0;
    left: 177px;
  }
</style>
