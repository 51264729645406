<template>
  <div v-if="note_for_id !== null" class="add_notes">
    <div class="notes_content">
      <div class="header">
        <span>Add Notes</span>
        <img
          src="@/assets/close_icon.svg"
          class="close_notes"
          @click="close_notes"
          alt="icon">
      </div>
      <textarea
        v-model="note"
        placeholder="Type your note here..."
        @input="errors = ''"
      ></textarea>
      <div class="controls">
        <p
          v-if="errors"
          class="errors"
        >{{ errors }}</p>
        <div class="buttons">
          <button
            class="discard_notes"
            @click="close_notes"
          >Discard</button>
          <button
            class="apply_notes"
            @click="apply_notes"
          >Save note</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  ADD_NOTE,
  UPDATE_NOTE_TARGET
} from '@/store/actions.type'

export default {
  name: 'Notes',
  components: {
  },
  data () {
    return {
      note: '',
      errors: ''
    }
  },
  computed: {
    ...mapGetters([
      'note_for_id'
    ])
  },
  methods: {
    ...mapActions({
      addCustomerNote: ADD_NOTE,
      updateNoteTarget: UPDATE_NOTE_TARGET
    }),
    apply_notes () {
      if (this.note) {
        this.addCustomerNote({
          content: this.note,
          id: this.note_for_id,
          kind: 'customer'
        }).then(() => {
          this.close_notes()
        })
      } else {
        this.errors = '*Note field can nott be blank'
      }
    },
    close_notes () {
      this.note = ''
      this.updateNoteTarget(null)
    }
  }
}
</script>

<style scoped>
  .add_notes {
    position: fixed;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    filter: blur(0) !important;
  }
  .add_notes .notes_content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
    width: 100%;
    max-width: 620px;
    /* background: green; */
  }
  .add_notes .notes_content .header {
    background: #000;
    padding: 25px 25px 15px;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  .add_notes .notes_content .header img {
    opacity: .8;
    cursor: pointer;
  }
  .add_notes .notes_content .header span {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  .add_notes .notes_content textarea {
    width: 100%;
    height: 280px;
    padding: 35px 45px;
    margin: 0;
    outline: 0;
    box-sizing: border-box;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    color: #000;
    resize: none;
  }
  .add_notes .notes_content textarea::placeholder {
    color: #C4C4C4;
  }

  .add_notes .notes_content .controls {
    background-color: #000;
    padding: 20px;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
  }
  .add_notes .notes_content .controls p {
    color: red;
    max-width: 460px;
    width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 10px 15px;
  }
  .add_notes .notes_content .controls .buttons {
    max-width: 460px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;
  }

  .add_notes .notes_content .controls button {
    max-width: 210px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 13px;
    margin: 0 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .add_notes .notes_content .controls .discard_notes {
    background-color: #000;
    border: 1px solid #FFFFFF;
  }
  .add_notes .notes_content .controls .discard_notes:hover {
    background-color: #fff;
    color: #000;
  }
  .add_notes .notes_content .controls .apply_notes {
    background-color: #1CB1B8;
    border: 1px solid #1CB1B8;
  }
  .add_notes .notes_content .controls .apply_notes:hover {
    background-color: #fff;
    color: #1CB1B8;
  }
</style>
