<template>
  <div class="first_time">
    <div class="message">
      <h4>Welcome, {{ user.name }}!</h4>
      <p>To get started, please install and download <a href="#">ClarView Extension</a> from Chrome Web Store</p>
    </div>
    <div class="navigation">
      <button><img src="@/assets/app_icon.svg" alt="logo"/>DOWNLOAD CLARVIEW</button>
      <div class="description">
        <img src="@/assets/chrome_icon.svg" class="chrome" alt="chrome" />
        <span class="chrome">Chrome Web Store</span>
        <div class="stars">
          <img src="@/assets/star.svg" class="star" alt="star" />
          <img src="@/assets/star.svg" class="star" alt="star" />
          <img src="@/assets/star.svg" class="star" alt="star" />
          <img src="@/assets/star.svg" class="star" alt="star" />
          <img src="@/assets/star.svg" class="star" alt="star" />
        </div>
        <span class="reviews">189k reviews</span>
      </div>
    </div>
    <img
      src="@/assets/close_icon.svg"
      class="close"
      alt="close"
      @click="$emit('hide')"
    />
  </div>
</template>

<script>
export default {
  name: 'FirstTime',
  components: {
  },
  data () {
    return {
      user: {
        name: 'Jeniffer'
      }
    }
  }
}
</script>

<style scoped>
  .first_time {
    background: linear-gradient(141.88deg, #1CB1B8 18.97%, #0F85B0 110.24%);
    border-radius: 0px 0px 30px 30px;
    padding: 35px 250px 35px 110px;
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: flex-start;
    box-sizing: border-box;
    position: relative;
  }
  .first_time img.close {
    position: absolute;
    cursor: pointer;
    top: 35px;
    right: 35px;
    width: 14px;
    height: 14px;
  }
  .first_time .navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .first_time .navigation button {
    padding: 12px;
    width: 460px;
    height: 90px;
    background: linear-gradient(0deg, rgba(15, 133, 176, 0.5), rgba(15, 133, 176, 0.5)), linear-gradient(355.03deg, #000000 -10.87%, rgba(0, 0, 0, 0) 109.78%);
    border: 1px solid rgba(43, 255, 247, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
    margin: 50px 0 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }
  .first_time .navigation button img {
    margin: 0;
    margin-right: 30px;
    border: 3px solid rgba(255, 255, 255, 0.08);
    border-radius: 3px;
    width: 66px;
    height: 66px;
  }
  .first_time .navigation .description {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
  }
  .first_time .navigation .description img.chrome {
    width: 33px;
    height: 33px;
    margin: 0 10px;
  }
  .first_time .navigation .description span {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    letter-spacing: 0.08em;
    color: #FFFFFF;
  }
  .first_time .navigation .description .stars {
    margin: 0 10px 0 20px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
  }
  .first_time .navigation .description .stars img{
    width: 16px;
    height: 17px;
    margin: 0 4px;
  }

  .first_time .message {
    width: 50%;
  }
  .first_time .message h4,
  .first_time .message p,
  .first_time .message p a {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    line-height: 44px;

    font-size: 24px;

    text-align: right;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin: 0;
    max-width: 450px;
  }
  .first_time .message h4 {
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 36px;
  }
  .first_time .message p a:hover {
    text-decoration: none;
  }
</style>
