<template>
  <div v-if="isDateFilter" class="date_range">
    <div class="content">
      <div class="header">
        <span>Select Date Range</span>
        <img
          src="@/assets/close_icon.svg"
          class="close_range"
          @click="toggle_filter"
          alt="icon">
      </div>
      <VueDatepickerUi
        v-model="selectedDate"
        range
        first-day-of-week="sunday"
        show-clear-button
        lang="en"
        @reset="reset"
      />
      <div class="controls">
        <div class="buttons">
          <button
            class="apply_filter"
            @click="apply_filter"
          >Apply filter</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-datepicker-ui/lib/vuedatepickerui.css'
import VueDatepickerUi from 'vue-datepicker-ui'
import {
  TOGGLE_ACTIVITY_DATE_FILTER,
  UPDATE_DATE_INTERVAL
} from '@/store/actions.type'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DateRange',
  components: {
    VueDatepickerUi
  },
  data () {
    return {
      selectedDate: [
        null,
        null
      ]
    }
  },
  computed: {
    ...mapGetters([
      'isDateFilter'
    ])
  },
  methods: {
    ...mapActions({
      toggle_filter: TOGGLE_ACTIVITY_DATE_FILTER,
      updateDateInterval: UPDATE_DATE_INTERVAL
    }),
    apply_filter () {
      if (this.selectedDate[0] && this.selectedDate[1]) {
        this.updateDateInterval({
          from: `${this.selectedDate[0].getFullYear()}-${this.selectedDate[0].getMonth() + 1}-${this.selectedDate[0].getDate()}`,
          to: `${this.selectedDate[1].getFullYear()}-${this.selectedDate[1].getMonth() + 1}-${this.selectedDate[1].getDate()}`
        })
        this.toggle_filter()
      }
    },
    reset () {
      this.selectedDate = [null, null]
    }
  }
}
</script>

<style>
.date_range {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  padding-top: 30px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  filter: blur(0) !important;
}

.date_range > .content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  width: 100%;
  max-width: 851px;
}
section.v-calendar,
section.v-calendar div.input-field.long,
section.v-calendar div.input-field input {
  width: 100%;
  border-radius: 0;
  cursor: pointer;
  font-family: Poppins;
}
section.v-calendar > .content {
  border: var(--v-calendar-input-border);
  border-top: 0;
  transform: translateY(0) !important;
  position:relative;
  top: 0;
}
section.v-calendar > .content .calendar.range::before {
  content: none;
}
.date_range > .content .header {
  background: #000;
  padding: 25px 25px 15px;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
}
.date_range > .content .header img {
  opacity: .8;
  cursor: pointer;
}
.date_range > .content .header span {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.date_range > .content .controls {
  background-color: #000;
  padding: 20px;
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
}
.date_range > .content .controls .buttons {
  max-width: 460px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
}

.date_range > .content .controls button {
  max-width: 210px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 13px;
  margin: 0 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.date_range > .content .controls .apply_filter {
  background-color: #1CB1B8;
  border: 1px solid #1CB1B8;
}
.date_range > .content .controls .apply_filter:hover {
  background-color: #fff;
  color: #1CB1B8;
}
</style>
