<template>
  <div
    class="line_container"
    :class="[kind, {'active': active_line === kind}]"
    :style="{ transform: `${pos_x}`, width: `${width}px` }"
  >
    <template
      v-for="year in activity_customer.active_years"
    >
      <div
        class="line_month_container"
        :class="[{
          'active': active_type.kind === 'days'
        }]"
        :style="{
          height:
            active_type.kind === 'months' && month.active_years_days[year] ?
              `${config[active_type.date_range].month_height_px}px` :
              `${config[active_type.date_range].day_height_px * (month.active_years_days[year] ? month.active_years_days[year].length : 0)}px`
        }"
        v-for="month in activity_customer.active_months"
        :key="`${year}${month.id}-month-${Math.random()}`"
      >
        <div
          v-if="active_type.kind === 'months' && month.active_years_days[year]"
          class="month_wrapper"
        >
          <div
            v-for="(item, idx) in config[active_type.date_range].month_points"
            :key="`month-point${idx}-${Math.random()}`"
            class="month_point"
            :class="[`item-${item}-${idx}`, `${year}`, `${month.id}`]"
            :style="{ height: activity_customer.display_data.month_styles[active_type.date_range][idx].height }"
          >
            <span
              v-if="
                activity_customer.display_data.months[active_type.date_range][month.id] &&
                activity_customer.display_data.months[active_type.date_range][month.id][kind] &&
                activity_customer.display_data.months[active_type.date_range][month.id][kind][idx] &&
                activity_customer.display_data.months[active_type.date_range][month.id][kind][idx][year] &&
                activity_customer.display_data.months[active_type.date_range][month.id][kind][idx][year].length > 0"
              :style="{ top: activity_customer.display_data.month_styles[active_type.date_range][idx].list[month.id][kind][year].top }"
              @click="show_messages(month.id, null, kind, idx, year, `item-${month.id}-m-${kind}-${item}-${idx}-${year}`)"
              :class="{active_point: active_point === `item-${month.id}-m-${kind}-${item}-${idx}-${year}`}"
            >
              {{ activity_customer.display_data.months[active_type.date_range][month.id][kind][idx][year].length }}
            </span>
          </div>
        </div>

        <div
          v-if="active_type.kind == 'days'"
          class="days_wrapper"
        >
          <div
            v-for="day in month.active_years_days[year]"
            :key="`${day}-${Math.random()}`"
            :style="{ height: `${config[active_type.date_range].day_height_px}px` }"
            class="day"
            :class="[`${day}`]"
            :ref="`day${year}${month.id}${day}`"
          >
            <div
              v-for="(item, idx) in config[active_type.date_range].day_points"
              :key="`item${item}-${Math.random()}`"
              class="day_point"
              :class="[`${item}`]"
              :style="{ height: activity_customer.display_data.day_styles[active_type.date_range][idx].height }"
            >
              <span
                v-if="
                  activity_customer.display_data.days[active_type.date_range][month.id][day][kind] &&
                  activity_customer.display_data.days[active_type.date_range][month.id][day][kind][idx][year] &&
                  activity_customer.display_data.days[active_type.date_range][month.id][day][kind][idx][year].length > 0"
                :style="{ top: activity_customer.display_data.day_styles[active_type.date_range][idx].list[month.id][day][kind][year].top }"
                @click="show_messages(month.id, day, kind, idx, year, `item-${month.id}-${day}-${kind}-${item}-${idx}-${year}`)"
                :class="{active_point: active_point === `item-${month.id}-${day}-${kind}-${item}-${idx}-${year}`}"
              >
                {{ activity_customer.display_data.days[active_type.date_range][month.id][day][kind][idx][year].length }}
              </span>
            </div>
          </div>
        </div>

      </div>
    </template>
    <div class="end_line" :style="{width: `${width}px`}">
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
/* *,
  order: `${year}${month.current_position > 10 ? month.current_position : `0${month.current_position}` }` */
import { mapGetters, mapActions } from 'vuex'
import {
  UPDATE_ACTIVITY_SHOWING_MESSAGES,
  UPDATE_ACTIVE_POINT
} from '@/store/actions.type'

export default {
  name: 'TimeLineActivityLine',
  props: {
    kind: String
  },
  data () {
    return {
      line_items: [
        'team',
        'linkedin',
        'salesforce',
        // 'twitter',
        // 'сhorus',
        'gmail'
      ],
      pos_x: 'translateX(0px)',
      width: 60
    }
  },
  watch: {
    'active_line' () {
      this.calc_pos()
    }
  },
  computed: {
    ...mapGetters([
      'config',
      'activity_customer',
      'activity_showing_messages',
      'active_line',
      'active_point',
      'active_type'
    ])
  },
  methods: {
    ...mapActions({
      setShowingMessages: UPDATE_ACTIVITY_SHOWING_MESSAGES,
      setPoint: UPDATE_ACTIVE_POINT
    }),
    calc_pos () {
      let pos = ''
      if (this.active_line === '') {
        pos = 'translateX(0px)'
      } else {
        if (this.active_line === this.line_items[this.line_items.length - 1]) {
          pos = 'translateX(0px)'
        } else if (this.kind === this.active_line) {
          const steps = this.line_items.length - this.line_items.indexOf(this.kind) - 1
          pos = `translateX(${steps * this.width}px)`
        } else {
          if (this.line_items.indexOf(this.kind) > this.line_items.indexOf(this.active_line)) {
            pos = `translateX(-${this.width}px)`
          } else {
            pos = 'translateX(0px)'
          }
        }
      }
      this.pos_x = pos
    },
    show_messages (id, day, kind, index, year, refPoint) {
      this.setPoint(refPoint)
      const items = day === null
        ? this.activity_customer.display_data.months[this.active_type.date_range][id][kind][index][year]
        : this.activity_customer.display_data.days[this.active_type.date_range][id][day][kind][index][year]

      items.sort(function (a, b) {
        if (a.created) {
          return new Date(b.created) - new Date(a.created)
        } else {
          return new Date(b.sent) - new Date(a.sent)
        }
      })

      var dates = items.map((item) => {
        if (item.created) {
          return new Date(item.created)
        } else {
          return new Date(item.sent)
        }
      })
      var maxDate = new Date(Math.max.apply(null, dates))
      var minDate = new Date(Math.min.apply(null, dates))

      const date = maxDate.getDate() === minDate.getDate() ? minDate.getDate() : `${minDate.getDate()}-${maxDate.getDate()}`
      const range = `${date} ${dates[0].toLocaleString('default', { month: 'long' })} ${dates[0].getFullYear()}`

      if (this.activity_showing_messages.date_range === range && this.activity_showing_messages.kind === kind) {
        this.$emit('active', this.kind)
        return
      }

      this.$emit('active', this.kind)

      if (this.kind === kind) {
        this.setShowingMessages({ date_range: range, messages: [], kind: kind })
        setTimeout(() => {
          this.setShowingMessages({ date_range: range, messages: items, kind: kind })
        }, 10)
      } else {
        this.setShowingMessages({ date_range: range, messages: items, kind: kind })
      }
    }
  }
}
</script>

<style scoped>
.line_container {
  transition: transform .3s cubic-bezier(1.0, 0.5, 0.8, 1.0), opacity 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  transform: translateX(0px);
  /* width: 70px; */
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: unset;
  justify-content: flex-start;
  /* opacity: .6; */
  /* order: 0; */
}
.line_container:hover,
.line_container.active {
  /* order: 5; */
  opacity: 1;
}
.line_container .end_line {
  position: absolute;
  width: 70px;
  height: calc(100vh - 232px);
  left: 0;
  bottom: 0;
  z-index: -1;
}
.line_container .end_line .line {
  height: -webkit-fill-available;
}
.line_container .end_line .line,
.line_month_container .days_wrapper,
.line_month_container .month_wrapper {
  width: 7px;
  margin: 0 auto;
  transition: transform 0s linear;
}
.line_container:hover .end_line .line,
.line_container:hover .line_month_container .days_wrapper,
.line_container:hover .line_month_container .month_wrapper {
  transform: scaleX(1.33)
}
.line_container:hover .line_month_container .days_wrapper .day_point > span,
.line_container:hover .line_month_container .month_wrapper .month_point > span {
  transform: scaleX(0.75);
}
/** NEW LINES */
.line_container.team .end_line .line,
.line_container.team .days_wrapper,
.line_container.team .month_wrapper,
.line_container.team .days_wrapper .day_point span,
.line_container.team .month_wrapper .month_point span,
.line_container.team .days_wrapper .day_point > span:before,
.line_container.team .month_wrapper .month_point > span:before {
  background: #9F6BE1;
}

.line_container.linkedin .end_line .line,
.line_container.linkedin .days_wrapper,
.line_container.linkedin .month_wrapper,
.line_container.linkedin .days_wrapper .day_point span,
.line_container.linkedin .month_wrapper .month_point span,
.line_container.linkedin .days_wrapper .day_point > span:before,
.line_container.linkedin .month_wrapper .month_point > span:before {
  background: #1568BF;
}

.line_container.gmail .end_line .line,
.line_container.gmail .days_wrapper,
.line_container.gmail .month_wrapper,
.line_container.gmail .days_wrapper .day_point span,
.line_container.gmail .month_wrapper .month_point span,
.line_container.gmail .days_wrapper .day_point > span:before,
.line_container.gmail .month_wrapper .month_point > span:before {
  background: #DE2B1F;
}

.line_container.salesforce .end_line .line,
.line_container.salesforce .days_wrapper,
.line_container.salesforce .month_wrapper,
.line_container.salesforce .days_wrapper .day_point span,
.line_container.salesforce .month_wrapper .month_point span,
.line_container.salesforce .days_wrapper .day_point > span:before,
.line_container.salesforce .month_wrapper .month_point > span:before {
  background: #00A1E0;
}

.line_container.twitter .end_line .line,
.line_container.twitter .days_wrapper,
.line_container.twitter .month_wrapper,
.line_container.twitter .days_wrapper .day_point span,
.line_container.twitter .month_wrapper .month_point span,
.line_container.twitter .days_wrapper .day_point > span:before,
.line_container.twitter .month_wrapper .month_point > span:before {
  background: #18D3DF;
}

.line_container.сhorus .end_line .line,
.line_container.сhorus .days_wrapper,
.line_container.сhorus .month_wrapper,
.line_container.сhorus .days_wrapper .day_point span,
.line_container.сhorus .month_wrapper .month_point span,
.line_container.сhorus .days_wrapper .day_point > span:before,
.line_container.сhorus .month_wrapper .month_point > span:before {
  background: #4085E6;
}
/** END NEW LINES */

.line_month_container .days_wrapper .day_point,
.line_month_container .month_wrapper .month_point {
  position: relative;
}
.line_month_container .days_wrapper .day_point > span,
.line_month_container .month_wrapper .month_point > span {
  display: block;
  position: absolute;
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  border-radius: 50%;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  text-align: center;

  color: #FFFFFF;
  cursor: pointer;
  z-index: 1;
  transition: transform 0s linear;
}
.line_container:hover .line_month_container .days_wrapper .day_point > span:hover,
.line_container:hover .line_month_container .month_wrapper .month_point > span:hover {
  transform: scaleX(1);
  transform: scaleY(1.3);
}
.line_month_container .days_wrapper .day_point > span.active_point:before,
.line_month_container .month_wrapper .month_point > span.active_point:before,
.line_month_container .days_wrapper .day_point > span:hover:before,
.line_month_container .month_wrapper .month_point > span:hover:before {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  top: calc(50% - 15px);
  left: 0;
  position: absolute;
  z-index: -1;
  -webkit-filter: drop-shadow(0px 2px 2px rgba(130,130,130,1));
  filter: drop-shadow(0px 2px 2px rgba(130,130,130,1));
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')";
  animation: pulse-dot 3s infinite;
}
.line_container:hover .line_month_container {
  z-index: 10;
}
@keyframes pulse-dot {
  0% { transform: scale(1); }
  25% { transform: scale(1.1); }
  50% { transform: scale(1); }
  75% { transform: scale(1.1); }
  100% { transform: scale(1); }
}
</style>
