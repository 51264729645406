<template>
  <div class="search">
    <input
      placeholder="Search Customer"
    />
    <img src="@/assets/icons/search.svg" alt="search">
  </div>
</template>

<script>
export default {
  name: 'Search'
}
</script>

<style scoped>
.search {
  width: fit-content;
  height: 50px;
  position: relative;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 27px;
  margin-bottom: 12px;
}
.search img {
  position: absolute;
  right: 16px;
  top: calc(50% - 12px);
}
.search input {
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 12px 50px 12px 30px;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */
  color: #858585;
  outline: none;
}
</style>
