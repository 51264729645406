<template>
  <div class="side">
    <div class="current_customer">
      <img
        v-if="activity_customer.logo"
        :src="activity_customer.logo"
        @click="$router.push(`/customer_profile/${activity_customer.id}`)"
        @load="onImgLoad"
        @error="imgUrlWrong"
        alt="Customer Photo">
      <StagesBadge :stage="activity_customer.stage"/>
    </div>
    <!-- <InsightSquared /> -->
    <!-- <DateFilter /> -->
    <ColorLegend />
    <ContentType />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import StagesBadge from '@/components/StagesBadge.vue'
// import InsightSquared from '@/components/activity/side/Insight.vue'
// import DateFilter from '@/components/activity/side/Dates.vue'
import ContentType from '@/components/activity/side/ContentType.vue'
import ColorLegend from '@/components/activity/side/ColorLegend.vue'

export default {
  name: 'ActivitySideBar',
  components: {
    StagesBadge,
    // InsightSquared,
    // DateFilter,
    ColorLegend,
    ContentType
  },
  computed: {
    ...mapGetters([
      'activity_customer',
      'user_photo'
    ])
  },
  methods: {
    imgUrlWrong (event) {
      event.target.style.display = 'none'
    },
    onImgLoad (event) {
      event.target.classList.add('loaded')
    }
  }
}
</script>

<style scoped>
  .side {
    max-width: 330px;
    width: 100%;

    padding: 25px 0;
    background-color: rgba(196, 196, 196, 0.12);

    align-self: stretch;

    height: calc(100vh - 82px);
    overflow-y: scroll;
    box-sizing: border-box;
    -ms-overflow-style: none;  /*IE and Edge*/
    scrollbar-width: none;  /*Firefox*/
  }
  .side::-webkit-scrollbar {
    display: none;
  }
  .current_customer {
    box-sizing: border-box;
    margin: 0 27px 33px;
    position: relative;
  }
  .current_customer img {
    display: none;
    width: 0px;
    height: 0px;
  }
  .current_customer img.loaded {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 200px;
    border-radius: 10px;
    cursor: pointer;
  }
  .current_customer p {
    display: block;
    width: 120px;
    position: absolute;
    left: calc(50% - 60px);
    bottom: -20px;

    font-family: Poppins;
    font-style: normal;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
  }
</style>
