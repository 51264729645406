<template>
  <div class="customer">
    <div class="left_side">
      <img
        v-if="user.logo"
        :src="user.logo"
        @load="onImgLoad"
        @error="imgUrlWrong"
        alt="Logo">
      <StagesBadge :stage="user.stage"/>
      <p class="created">
        First interaction <span>{{ getCreatedDate }}</span>
      </p>
    </div>
    <div class="description">
      <p class="customer_name">{{ user.name }}</p>
      <p
        v-if="user.contacts"
        class="contacts_length"
      >
        contact persons &middot; <span v-if="user.contacts.length > 0">{{ user.contacts.length }}</span><span v-else>0</span>
      </p>
      <div
        v-if="user.contacts && user.contacts.length > 0"
        class="contacts_wrapper"
      >
        <Contact
          v-for="el in user.contacts.slice(0,3)"
          :contact="el"
          :key="el.id"
        />
      </div>
    </div>
    <div class="controls">
      <button
        class="activity"
        @click="goToActivity(user.id)"
      >
        VIEW ACTIVITY
      </button>
      <button
        class="profile"
        @click="$router.push(`/customer_profile/${user.id}`)"
      >
        CUSTOMER PROFILE
      </button>
      <button
        class="notes"
        @click="updateNoteTarget(this.user.id)"
      >
        ADD NOTES
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  UPDATE_ACTIVITY_CUSTOMER,
  FETCH_CUSTOMER_CONTACTS,
  UPDATE_ACTIVITY_SHOWING_MESSAGES,
  UPDATE_NOTE_TARGET
} from '@/store/actions.type'

import Contact from '@/components/customers/Contact.vue'
import StagesBadge from '@/components/StagesBadge.vue'

export default {
  name: 'Customer',
  components: {
    StagesBadge,
    Contact
  },
  props: {
    user: Object
  },
  watch: {
  },
  computed: {
    ...mapGetters([
      'activity_customer_id',
      'user_photo'
    ]),
    getCreatedDate () {
      const date = new Date(this.user.created)
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      return `${day} ${date.toLocaleString('default', { year: 'numeric', month: 'short' })}`
    }
  },
  created () {
    this.fetchContacts(this.user.id)
  },
  data () {
    return {
      show_notes: false,
      note_customer_id: null
    }
  },
  methods: {
    ...mapActions({
      clearShowingMessages: UPDATE_ACTIVITY_SHOWING_MESSAGES,
      setActivityCustomer: UPDATE_ACTIVITY_CUSTOMER,
      fetchContacts: FETCH_CUSTOMER_CONTACTS,
      updateNoteTarget: UPDATE_NOTE_TARGET
    }),
    goToActivity (id) {
      this.setActivityCustomer(id)
      this.clearShowingMessages({ date_range: '', messages: [], kind: '' })
      this.$router.push('/activity')
    },
    imgUrlWrong (event) {
      event.target.style.display = 'none'
    },
    onImgLoad (event) {
      event.target.classList.add('loaded')
    }
  }
}
</script>

<style scoped>
  .customer {
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 10px;

    padding: 20px 25px 17px 20px;
    margin: 0;
    margin-top: 30px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .customer .add_notes {
    position: fixed;
    top: 100px;
    left: calc(50% - 100px);
    border: 1px solid hsl(0deg 0% 93%);
    background-color: hsl(0deg 0% 93% / 50%);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px 25px 17px 20px;
  }
  .customer .left_side {
    max-width: 200px;
    width: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .customer .left_side img.loaded {
    object-fit: cover;
    height: 140px;
    width: 200px;

    padding: 16px 19px;
    margin: 0;
    margin-bottom: 15px;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .customer .left_side p {
    margin: 0;
    font-family: Poppins;
    font-style: normal;
    font-size: 12px;
    text-align: center;
  }
  .customer .left_side .stage {
    margin-bottom: 7px;
}
  .customer .left_side .created {
    font-weight: normal;
    line-height: 15px;
    color: #858585;
  }
  .customer .left_side .created span {
    color: #333;
  }

  .customer .description {
    width: 100%;
    padding: 0 30px;
  }
  .customer .description p {
    font-family: Poppins;
    font-style: normal;
    letter-spacing: 0.05em;
    font-weight: normal;
    margin: 0;
    width: 100%;
  }
  .customer .description .customer_name {
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    color: #343333;
    margin-bottom: 6px;
  }
  .customer .description .contacts_length {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #858585;
    margin-bottom: 17px;
  }
  .customer .description .contacts_length span {
    font-weight: bold;
    padding: 0 0 0 3px;
  }
  .customer .description .contacts_wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: self-start;
  }

  .customer .controls button {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    width: 190px;
    height: 50px;
    display: block;
    position: relative;

    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #1CB1B8;
    margin: 0;
    margin-bottom: 20px;
    padding: 14px 20px;

    cursor: pointer;
    transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .customer .controls .activity {
    background: #1CB1B8;
    color: #FFFFFF;
  }
  .customer .controls .activity:hover {
    background: #FFFFFF;
    color: #1CB1B8;
  }
  .customer .controls .notes,
  .customer .controls .profile {
    background: #fff;
    color: hsl(183deg 74% 42% / 80%);
  }
  .customer .controls .notes:hover,
  .customer .controls .profile:hover {
    background: hsl(183deg 74% 42% / 80%);
    color: #fff;
  }
  .customer .controls .notes {
    margin-bottom: 0;
  }
  .customer .controls .notes:hover:after {
    background: linear-gradient(hsl(0deg 0% 100%) 0 0), linear-gradient(hsl(0deg 0% 100%) 0 0), hsl(0deg 0% 0% / 0%);
    background-position: center;
    background-size: 100% 1px,1px 100%;
    background-repeat: no-repeat;
  }
  .customer .controls .notes:after {
    content: '';
    display: inline-block;
    width: 13px;
    height: 13px;
    background: linear-gradient(hsl(183deg 74% 42%) 0 0), linear-gradient(hsl(183deg 74% 42%) 0 0), hsl(0deg 0% 0% / 0%);
    background-position: center;
    background-size: 100% 1px,1px 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: 20px;
    top: calc(50% - 6px);
  }
</style>
