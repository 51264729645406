<template>
  <div class="stages">
    <span @click="show = !show">
      Stage
      <i
        class="arrow up"
        :class="{down: !show, up: show}"
      />
    </span>
    <transition name="fade">
      <ul v-if="show">
        <li class="onboarding">Onboarding</li>
        <li class="onborded">Onborded</li>
        <li class="engaged">Engaged</li>
        <li class="renewal">Renewal</li>
        <li class="renewed">Renewed</li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Stages',
  data () {
    return {
      show: false
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  max-height: 0px;
  padding: 0;
  opacity: 0;
}

ul {
  margin: 0;
  max-height: 300px;
  list-style: none;
  padding: 21px 0 7px;
  opacity: 1;
  overflow: hidden;
}
ul li {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  margin: 0;
  margin-bottom: 16px;
  padding: 0;
  padding-left: 30px;
  position: relative;
}
ul li:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  top: 3px;
  left: 0;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 4px;
}
ul li:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 8px;
  left: 5px;
  border-radius: 3px;
  box-sizing: border-box;
}
li.onboarding:after {
  background: #65DE62;
}
li.onborded:after {
  background: #40CFD8;
}
li.engaged:after {
  background: #A2A7F3;
}
li.renewal:after {
  background: #F688F9;
}
li.renewed:after {
  background: #9F6BE1;
}

.stages {
  width: calc(100% - 54px);
  min-height: 50px;
  position: relative;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 27px;
  margin-bottom: 12px;
  padding: 12px 22px 0 30px;
}
.stages img {
  position: absolute;
  right: 16px;
  top: calc(50% - 12px);
}
.stages .arrow {
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  position: absolute;
  right: 22px;
}
.up {
  top: 23px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.down {
  top: 16px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.stages span {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  display: block;
  color: #333333;
  opacity: 0.8;
  cursor: pointer;
}
</style>
