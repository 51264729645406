<template>
  <div v-if="all_notes.notes.length > 0" class="show_notes">
    <div class="notes_content">
      <div class="header">
        <span>{{ all_notes.customer.name }}<span>'s NOTES &bull; </span>{{ all_notes.notes.length }}</span>
        <img
          src="@/assets/close_icon.svg"
          class="close_notes"
          @click="close_notes"
          alt="icon">
      </div>
      <div class="notes_wrapper">
        <Note
          v-for="note in all_notes.notes"
          :key="note.id"
          :note="note"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  SHOW_NOTES
} from '@/store/actions.type'

import Note from '@/components/profile/Note.vue'

export default {
  name: 'ShowNotes',
  components: {
    Note
  },
  computed: {
    ...mapGetters([
      'all_notes'
    ])
  },
  methods: {
    ...mapActions({
      closeAllNotes: SHOW_NOTES
    }),
    close_notes () {
      this.closeAllNotes({ notes: [], customer: null })
    }
  }
}
</script>

<style scoped>
  .show_notes {
    position: fixed;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    filter: blur(0) !important;
  }
  .show_notes .notes_content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
    width: 100%;
    max-width: 620px;
    background: #FFFFFF;
    box-shadow: 3px 1px 21px 7px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  .show_notes .notes_content .notes_wrapper {
    width: 100%;
    height: 100%;
    max-height: 400px;
    box-sizing: border-box;
    padding: 25px;
    /* background-color: #FFFFFF; */
    overflow-y: scroll;
  }
  .show_notes .notes_content .header {
    background: #000;
    padding: 25px 25px 15px;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  .show_notes .notes_content .header img {
    opacity: .8;
    cursor: pointer;
  }
  .show_notes .notes_content .header span {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  .show_notes .notes_content .header span span {
    font-weight: normal;
    text-transform: none;
  }
</style>
