import { AUTH_URL, SIGN_UP_URL } from './config.js'
import ApiService from '@/common/api.service'

export const AuthService = {
  getToken (params) {
    return ApiService.post(AUTH_URL, params)
  },
  signup (params) {
    return ApiService.post(SIGN_UP_URL, params)
  }
}

export default AuthService
