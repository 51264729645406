<template>
  <div class="messages">
    <div class="navigation">
      <nav class="top">
        <CustomersList />
      </nav>
      <nav class="bottom">
        <Popper class="filter-popover"
          @open:popper="filterButtonActive = true"
          @close:popper="filterButtonActive = false">
          <img :class="['filter_button', { active: filterButtonActive }]"
              src="@/assets/icons/filter_complex.svg" alt="filters">

          <template #content="{ close }">
            <div class="py-3">
              Include: <vSelect v-model="keywordsInclude" multiple taggable>
                <template #no-options>
                  Please Enter words to include in the Activities
                </template>
              </vSelect>
            </div>
            <div class="py-3">
              Exclude: <vSelect v-model="keywordsExclude" multiple taggable>
                <template #no-options>
                  Please Enter words to exclude from the Activities
                </template>
              </vSelect>
            </div>
            <button
              class="btn w-100"
              @click="applyFilterByKeywords(close)"
            >
              APPLY
            </button>
          </template>
        </Popper>
        <DateFilter />
      </nav>
    </div>
    <div class="messages_wrapper">
      <transition name="fade">
        <h1
          v-if="activity_showing_messages.kind !== '' && activity_showing_messages.kind "
          class="header"
        >
          Customer's
          <span
            v-if="activity_showing_messages.kind === 'team'"
            class="name"
          >
            AND OUR {{ activity_customer.name }}
          </span>
          <span
            v-else
            class="name"
          >
            {{ activity_customer.name }}
          </span>
          ACTIVITY for {{ activity_showing_messages.date_range }}
        </h1>
      </transition>
      <transition name="slide-fade">
        <div
          v-if="!!activity_showing_messages.messages.length"
          :class="[activity_showing_messages.kind]"
        >
          <SinglMessage
            v-for="message in activity_showing_messages.messages"
            :key="`${message.id}${Math.random()}`"
            :message="message"
            :kind="activity_showing_messages.kind"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  FETCH_CUSTOMER_ACTIVITY,
  ACTIVITY_SEARCH,
  UPDATE_ACTIVITY_SHOWING_MESSAGES
} from '@/store/actions.type'
import {
  SET_ACTIVITY_KEYWORDS_FILTER
} from '@/store/mutations.type.js'

import CustomersList from '@/components/activity/side/CustomersList.vue'
import DateFilter from '@/components/activity/side/Dates.vue'
import SinglMessage from '@/components/activity/activity_components/SinglMessage.vue'
import Popper from 'vue3-popper'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'MessagesBar',
  components: {
    SinglMessage,
    DateFilter,
    CustomersList,
    Popper,
    vSelect
  },

  data () {
    return {
      filterButtonActive: false,
      keywordsInclude: [],
      keywordsExclude: []
    }
  },

  computed: {
    ...mapGetters([
      'activity_showing_messages',
      'activity_customer',
      'activity_seach_query',
      'activity_keywords_filter'
    ])
  },

  watch: {
    activity_keywords_filter: {
      handler () {
        this.keywordsInclude = this.activity_keywords_filter.keywordsInclude || []
        this.keywordsExclude = this.activity_keywords_filter.keywordsExclude || []
      },
      deep: true
    }
  },

  methods: {
    ...mapActions({
      clearShowingMessages: UPDATE_ACTIVITY_SHOWING_MESSAGES,
      fetchCustomerActivity: FETCH_CUSTOMER_ACTIVITY,
      activitySearch: ACTIVITY_SEARCH
    }),

    applyFilterByKeywords (closeCallback) {
      closeCallback()

      this.$store.commit(SET_ACTIVITY_KEYWORDS_FILTER, { keywordsInclude: this.keywordsInclude, keywordsExclude: this.keywordsExclude })
      this.clearShowingMessages({ date_range: '', messages: [], kind: '' })

      // just a small crunch to be able to call correct BE method
      if (this.activity_seach_query) {
        this.activitySearch()
      } else {
        this.fetchCustomerActivity()
      }
    }
  }
}
</script>

<style scoped>
  .messages .navigation .top {
    width: 100%;
    height: 50px;
    margin: 32px 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .messages .navigation .bottom {
    width: 100%;
    height: 50px;
    margin: 30px 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .fade-enter-active {
    transition: opacity .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    opacity: 0;
  }
  .fade-leave-active {
    transition: opacity .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter-to {
    opacity: 1;
  }
  .fade-leave-to {
    opacity: 0;
  }

  /** --- */

  .slide-fade-enter-active {
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    transform: translateX(100px);
    opacity: 0;
  }
  .slide-fade-enter-to {
    transform: translateX(0px);
    opacity: 1;
  }
  .slide-fade-leave-active {
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-leave-to {
    transform: translateX(100px);
    opacity: 0;
  }

  .messages {
    padding: 0;
    max-width: 420px;
    width: 100%;
    margin: 0 0 0 30px;
    background-color: #fff;
    align-self: stretch;

    height: calc(100vh - 82px);
    /* overflow-y: scroll; */
    box-sizing: border-box;
    /* -ms-overflow-style: none;  IE and Edge */
    /* scrollbar-width: none;  Firefox */
  }
  .messages_wrapper {
    padding: 0;
    max-width: 420px;
    width: 100%;
    margin: 0;
    background-color: #fff;
    align-self: stretch;

    height: calc(100vh - 278px);
    overflow-y: scroll;
    box-sizing: border-box;
    -ms-overflow-style: none;  /*IE and Edge*/
    scrollbar-width: none;  /*Firefox*/
  }
  .messages_wrapper::-webkit-scrollbar {
    display: none;
  }
  /* .messages::-webkit-scrollbar {
    display: none;
  } */
  .messages h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin: 0;
    margin-bottom: 7px;
    padding: 10px 0;
    text-transform: uppercase;
  }
  .messages h1 span.name {
    font-weight: bold;
  }

  .filter_button {
    align-self: center;
    padding: 10px;
    border: 2px solid transparent;
    border-radius: 24px;
  }
  .filter_button.active {
    border-color: #1CB1B8;
  }
  .filter-popover {
    font-family: Poppins;
    padding-right: 10px;
  }
  :deep(.popper) {
    min-width: 25vw;
  }
  /* It should be generic button class */
  .btn {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    width: 190px;
    height: 50px;
    display: block;
    position: relative;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #1CB1B8;
    margin: 0;
    padding: 14px 20px;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    background: #fff;
    color: hsl(183deg 74% 42% / 80%);
  }
  .btn:hover {
    background: hsl(183deg 74% 42% / 80%);
    color: #fff;
  }
  .w-100 {
    width: 100%;
  }
  .py-3 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
</style>

<style>
  :root {
    --popper-theme-background-color: #ffffff;
    --popper-theme-background-color-hover: #ffffff;
    --popper-theme-padding: 20px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>
