<template>
  <div
    v-if="month.active_years_days[year]"
    class="month_container"
    :style="{
      height: type.kind === 'months' ? `${config[type.date_range].month_height_px}px` : `${config[type.date_range].day_height_px * month.active_years_days[year].length}px`
    }"
  >
    <div class="navigation">
      <div
        v-if="type.kind === 'months'"
        class="month"
      >
        <div class="month_controls">
          <span
            :data-time="`month-${year}-${month.id}-1`"
          >{{ month.name }} <template v-if="month.id === 1">{{ year }}</template></span>
        </div>
        <template
          v-if="
            activity_customer.display_data.months[active_type.date_range][month.id] &&
            activity_customer.display_data.months[active_type.date_range][month.id].salesforce"
        >
          <template
            v-for="index in activity_customer.display_data.months[active_type.date_range][month.id].salesforce"
            :key="index"
          >
            <template v-if="index[year] && index[year].length > 0">
              <div class="salesforce">
                <span v-if="index[year][0].stage" class="salesforce">
                  {{ index[year][0].stage.split(' ')[1] }}
                </span>
              </div>
            </template>
          </template>
        </template>
      </div>

      <div
        v-if="type.kind === 'days'"
        class="days"
      >
        <div
          v-for="day in month.active_years_days[year]"
          :key="`month_block${day}${year}-${Math.random()}`"
          class="day"
          :style="{
            height: `${config[type.date_range].day_height_px}px`
          }"
        >
          <div class="day_header">
            <span
              :data-time="`day-${year}-${month.id}-${day}`"
              :data-test="`${Object.keys(activity_customer.display_data.days[active_type.date_range][month.id])[0]}-${day}`"
            >{{ day >= 10 ? day : `0${day}` }} {{ month.name }} <template v-if="month.id === 1 && Object.keys(activity_customer.display_data.days[active_type.date_range][month.id])[0] === day.toString()">{{ year }}</template></span>
          </div>
          <template
            v-if="
              activity_customer.display_data.days[active_type.date_range][month.id][day] &&
              activity_customer.display_data.days[active_type.date_range][month.id][day].salesforce"
          >
            <template
              v-for="index in activity_customer.display_data.days[active_type.date_range][month.id][day].salesforce"
              :key="index"
            >
              <template v-if="index[year] && index[year].length > 0">
                <div class="salesforce">
                  <span v-if="index[year][0].stage" class="salesforce">
                    {{ index[year][0].stage.split(' ')[1] }}
                  </span>
                </div>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// order: `-${year}${month.current_position > 10 ? month.current_position : `0${month.current_position}` }`

export default {
  name: 'TimeLineNavigation',
  props: {
    type: Object,
    month: Object,
    year: Number
  },
  methods: {
    days_condition (day) {
      return Object.keys(this.activity_customer.display_data.days[this.active_type.date_range][this.month.id])[0] === day
    }
  },
  computed: {
    ...mapGetters([
      'config',
      'active_type',
      'activity_customer'
    ])
  }
}
</script>

<style scoped>
/** + */
.month_container {
  width: 183px;
  box-sizing: border-box;
  overflow: hidden;
  transition: height 2s ease-in-out, transform 1s ease-in-out, opacity 2s ease-in-out;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
}
.month_container.hidden {
  height: 0 !important;
  overflow: hidden;
}
/** + */
.month_container .navigation {
  max-width: 177px;
  width: 100%;
  border: none;
  box-sizing: border-box;
  position: relative;
  height: 100%;
}
.month_container .month .month_controls,
.month_container .days .day_header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  height: 34px;
  position: relative;
  padding-right: 2px;
}
.month_container .days .day_header:after {
  content: '';
  width: 9px;
  height: 9px;
  position: absolute;
  top: 10px;
  right: -5px;
  border-radius: 50%;
  background: #C4C4C4;
  border: 2px solid #FFFFFF;
}
.month_container .days .day_header span,
.month_container .month span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #333333;
  padding: 0;
  padding-right: 33px;
}
.month_container .days div.salesforce,
.month_container .month div.salesforce {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-end;
}
.month_container .days span.salesforce,
.month_container .month span.salesforce {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #fff;
  padding: 0;
  width: auto;
  text-align: center;
  /* display: block; */
  padding: 0 15px;
  margin: 0 33px 0 0;
  background: #00A1E0;
  border-radius: 50px;
}
.month_container .days .day_header button,
.month_container .month button {
  position: relative;
  width: 34px;
  height: 34px;
  padding: 10px;
  margin: 0;
  margin-right: 19px;
  border: none;
  border-radius: 51px;
  background: #1CB1B8;
  cursor: pointer;
}
.month_container .month button:hover img {
  animation: pulse 3s infinite;
}
.month_container .days .day_header button:hover,
.month_container .month button:hover {
  -webkit-filter: drop-shadow(0px 2px 2px rgba(130,130,130,1));
  filter: drop-shadow(0px 2px 2px rgba(130,130,130,1));
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')";
}

.month_container .month {
  position: relative;
}
.month_container .month:after {
  content: '';
  width: 9px;
  height: 9px;
  position: absolute;
  /* top: calc(50% - 7px); */
  top: 10px;
  right: -5px;
  border-radius: 50%;
  background: #C4C4C4;
  border: 2px solid #FFFFFF;
}
.month_container .month button:hover:after {
  animation: pulse-dot 3s infinite;
}
.month_container .days .day_header {
  padding-right: 33px;
}
.month_container .days .day_header span {
  padding-right: 0;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    transform: scale(.8);
    opacity: .8;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  75% {
    transform: scale(.8);
    opacity: .8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes pulse-dot {
  0% { transform: scale(1); }
  25% { transform: scale(.8); }
  50% { transform: scale(1.1); }
  75% { transform: scale(.8); }
  100% { transform: scale(1); }
}
</style>
