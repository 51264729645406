<template>
  <div class="search">
    <img
      @click="search"
      class="search_btn"
      src="@/assets/icons/search.svg"
      alt="search"
    >
    <input
      v-model="query"
      @keyup.enter="search"
      placeholder="Search"
    />
    <img
      v-if="query !== ''"
      class="clear_btn"
      src="@/assets/icons/clear.svg"
      alt="clear_btn"
      @click="clear">
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  ACTIVITY_SEARCH,
  UPDATE_ACTIVITY_SEARCH_QUERY,
  UPDATE_ACTIVITY_SHOWING_MESSAGES,
  UPDATE_ACTIVE_POINT
} from '@/store/actions.type'

export default {
  name: 'Search',
  data () {
    return {
      query: ''
    }
  },
  computed: {
    ...mapGetters([
      'activity_seach_query'
    ])
  },
  created () {
    this.query = this.activity_seach_query
    // console.log('search')
  },
  watch: {
    query (val) {
      this.updateQuery(val)
    }
  },
  methods: {
    ...mapActions({
      clearShowingMessages: UPDATE_ACTIVITY_SHOWING_MESSAGES,
      activitySearch: ACTIVITY_SEARCH,
      updateQuery: UPDATE_ACTIVITY_SEARCH_QUERY,
      setPoint: UPDATE_ACTIVE_POINT
    }),
    search () {
      if (this.query === '') {
        return false
      }
      this.clearShowingMessages({ date_range: '', messages: [], kind: '' })
      this.setPoint('')
      this.activitySearch({ q: this.query })
    },
    clear () {
      this.updateQuery('')
      this.activitySearch({})
    }
  }
}
</script>

<style scoped>
.search {
  height: 50px;
  position: relative;
  background: #FFFFFF;
}
.search img {
  position: absolute;
  top: calc(50% - 12px);
}
.search img.search_btn {
  left: 16px;
}
.search img.clear_btn {
  right: 20px;
  cursor: pointer;
}
.search img:hover {
  -webkit-filter: drop-shadow(0px 2px 2px rgba(130,130,130,1));
  filter: drop-shadow(0px 2px 2px rgba(130,130,130,1));
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')";
}
.search input {
  border: none;
  width: 100%;
  height: 100%;
  padding: 15px 50px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  outline: none;
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 40px;
}
</style>
