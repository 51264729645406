<template>
  <div v-if="addContactPerson" class="new_contact_person">
    <div class="content">
      <div class="header">
        <span>Add New <b>{{ addContactPerson.for_customer.name }}</b>’s Contact Person</span>
        <img
          src="@/assets/close_icon.svg"
          class="close"
          @click="close"
          alt="icon">
      </div>
      <form action="#" name="add_new_contact_person" @submit.prevent="apply">
        <div class="names">
          <div class="input first_name">
            <input v-model="contact.first_name.value" type="text" placeholder="First name" name="first_name" required @input="contact.first_name.error = ''">
            <p v-if="!valid && contact.first_name.error" class="errors">{{ contact.first_name.error }}</p>
          </div>

          <div class="input last_name">
            <input v-model="contact.last_name.value" type="text" placeholder="Last name" name="last_name" required @input="contact.last_name.error = ''">
            <p v-if="!valid && contact.last_name.error" class="errors">{{ contact.last_name.error }}</p>
          </div>
        </div>

        <div class="input role">
          <input v-model="contact.role.value" type="text" placeholder="Role" name="role" @input="contact.role.error = ''">
        </div>
        <p v-if="!valid && contact.role.error" class="errors">{{ contact.role.error }}</p>

        <div class="input email">
          <input v-model="contact.email.value" type="email" placeholder="Email" name="email" required @input="contact.email.error = ''">
        </div>
        <p v-if="!valid && contact.email.error" class="errors">{{ contact.email.error }}</p>

        <div class="input phone">
          <input v-model="contact.phone.value" type="tel" placeholder="Phone Number" name="phone" @input="contact.phone.error = ''">
        </div>
        <p v-if="!valid && contact.phone.error" class="errors">{{ contact.phone.error }}</p>

        <div class="input linkedin">
          <img src="@/assets/icons/linkedin.svg" alt="linkedin">
          <input v-model="contact.linkedin.value" type="url" placeholder="https://www.linkedin.com/in/...." name="linkedin" required @input="contact.linkedin.error = ''">
        </div>
        <p v-if="!valid && contact.linkedin.error" class="errors">{{ contact.linkedin.error }}</p>

        <div class="input salesforce">
          <img src="@/assets/icons/salesforce.svg" alt="salesforce">
          <input v-model="contact.salesforce.value" type="url" placeholder="https://www.salesforce....." name="salesforce" @input="contact.salesforce.error = ''">
        </div>
        <p v-if="!valid && contact.salesforce.error" class="errors">{{ contact.salesforce.error }}</p>

        <p>OR</p>
        <button @click.prevent="import_person" type="button">import contact person details</button>
      </form>
      <div class="controls">
        <div class="buttons">
          <button
            class="discard"
            @click="close"
          >Discard</button>
          <button
            class="apply"
            @click="apply"
          >add new contact person</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  SET_ADD_NEW_CONTACT_PERSON,
  ADD_NEW_CONTACT_PERSON
} from '@/store/actions.type'

export default {
  name: 'NewContactPerson',
  components: {
  },
  data () {
    return {
      contact: {
        first_name: {
          value: '',
          error: ''
        },
        last_name: {
          value: '',
          error: ''
        },
        role: {
          value: '',
          error: ''
        },
        email: {
          value: '',
          error: ''
        },
        phone: {
          value: '',
          error: ''
        },
        linkedin: {
          value: '',
          error: ''
        },
        salesforce: {
          value: '',
          error: ''
        }
      },
      required: [
        'first_name',
        'last_name',
        'email',
        'linkedin'
      ],
      valid: false
    }
  },
  watch: {
    addContactPerson () {
      this.clear_popup()
    }
  },
  computed: {
    ...mapGetters([
      'addContactPerson'
    ])
  },
  methods: {
    ...mapActions({
      addPerson: ADD_NEW_CONTACT_PERSON,
      setAddPersonPopup: SET_ADD_NEW_CONTACT_PERSON
    }),
    validate () {
      this.valid = true
      Object.entries(this.contact).forEach(([key, obj]) => {
        if (!this.required.includes(key)) {
          return true
        }
        if (obj.value.length < 1) {
          obj.error = "This field can't be empty"
          this.valid = false
          return
        }
        if (key === 'email' && !this.validateEmail(obj.value)) {
          obj.error = 'Please, enter a valid email address'
          this.valid = false
        }
      })
    },
    apply () {
      this.validate()
      if (this.valid) {
        this.addPerson(this.customer_formatter()).then((data) => {
          this.clear_popup()
          this.close()
        })
      }
    },
    clear_popup () {
      Object.entries(this.contact).forEach(([key, obj]) => {
        obj.value = ''
        obj.error = ''
      })
    },
    customer_formatter () {
      const customerObj = {}
      Object.entries(this.contact).forEach(([key, obj]) => {
        customerObj[key] = obj.value
      })
      return {
        id: this.addContactPerson.for_customer.id,
        person: customerObj
      }
    },
    validateEmail (email) {
      return String(email).toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    close () {
      this.setAddPersonPopup(null)
    },
    import_person () {
      console.log('import_person', this.addContactPerson)
    }
  }
}
</script>

<style scoped>
  .new_contact_person {
    position: fixed;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    filter: blur(0) !important;
  }
  .new_contact_person .content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
    width: 100%;
    max-width: 740px;
    background: #FFFFFF;
    box-shadow: 3px 1px 21px 7px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  .new_contact_person .content button {
    cursor: pointer;
    outline: 0;
  }
  .new_contact_person .content form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 35px 70px;
    width: 100%;
    box-sizing: border-box;
  }
  .new_contact_person .content form .names {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  .new_contact_person .content form .names p.errors {
    margin: 15px 0 0;
  }
  .new_contact_person .content form .names .input {
    width: 48%;
  }
  .new_contact_person .content form p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    width: 100%;
    color: #6F758B;
    margin: 0 0 30px;
    padding: 15px 0 0;
  }
  .new_contact_person .content form p.errors {
    color: hsl(0deg 100% 50%);
    font-weight: 400;
    text-align: left;
    margin: 0 0 15px;
    padding: 0;
  }
  .new_contact_person .content form div.input {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin: 0 0 15px
  }
  .new_contact_person .content form input::placeholder {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }
  .new_contact_person .content form input {
    background: #FAFAFA;
    border: 0.5px solid #E4E4E4;
    box-shadow: inset 0px 1px 1px rgba(16, 32, 89, 0.12);
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    padding: 13px 22px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    outline: 0;
  }
  .new_contact_person .content form .input img {
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0 20px 0 0;
    display: block;
    box-sizing: border-box;
  }
  .new_contact_person .content form .input.salesforce,
  .new_contact_person .content form .input.linkedin {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .new_contact_person .content form .input.first_name::after,
  .new_contact_person .content form .input.last_name::after,
  .new_contact_person .content form .input.linkedin::after,
  .new_contact_person .content form .input.email::after {
    display: block;
    position: absolute;
    top: 13px;
    right: 22px;
    z-index: 1;
    content: 'required';
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #C4C4C4;
  }
  .new_contact_person .content form button {
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 13px;
    margin: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #1CB1B8;
    border: 1px solid #1CB1B8;
    transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .new_contact_person .content .header {
    background: #000;
    padding: 25px 25px 15px;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  .new_contact_person .content .header img {
    opacity: .8;
    cursor: pointer;
  }
  .new_contact_person .content .header span {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .new_contact_person .content .controls {
    background-color: #000;
    padding: 20px;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
  }
  .new_contact_person .content .controls p {
    color: red;
    max-width: 460px;
    width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 10px 15px;
  }
  .new_contact_person .content .controls .buttons {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;
  }

  .new_contact_person .content .controls button {
    max-width: 210px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 13px;
    margin: 0 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .new_contact_person .content .controls .discard {
    background-color: #000;
    border: 1px solid #FFFFFF;
  }
  .new_contact_person .content .controls .discard:hover {
    background-color: #fff;
    color: #000;
  }
  .new_contact_person .content .controls .apply {
    max-width: 370px;
    background-color: #1CB1B8;
    border: 1px solid #1CB1B8;
  }
  .new_contact_person .content form button:hover,
  .new_contact_person .content .controls .apply:hover {
    background-color: #fff;
    color: #1CB1B8;
  }
</style>
