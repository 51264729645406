import axios from 'axios'
import router from '../router'
import { SERVER_URL } from './config.js'

export const ApiService = {
  init () {
    axios.defaults.baseURL = SERVER_URL
    axios.defaults.headers.common['Content-Type'] = 'application/json'
  },
  setToken (token) {
    axios.defaults.headers.common.Authorization = token.length > 0 ? `Token ${token}` : ''
  },
  get (resource, headers) {
    return axios
      .get(resource, {
        headers: headers
      })
      .catch((error) => {
        if (error.response.status === 401) {
          router.push('/sign-in')
        }
        return Promise.reject(error.response)
      })
  },

  post (resource, params) {
    return axios.post(`${resource}`, params)
      .catch((error) => {
        if (error.response.status === 401) {
          router.push('/sign-in')
        }
        return Promise.reject(error.response)
      })
  },
  query (resource, params) {
    return axios.get(resource, {
      params: params
    })
      .catch((error) => {
        if (error.response.status === 401) {
          router.push('/sign-in')
        }
        return Promise.reject(error.toJSON())
      })
  }
}

export default ApiService
