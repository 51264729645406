<template>
  <div class="sources">
    <span @click="show = !show">
      Content Type
      <i
        class="arrow up"
        :class="{down: !show, up: show}"
      />
    </span>
    <transition name="fade">
      <ul v-if="show">
        <li class="likes"><div class="legend_icon"><img src="@/assets/icons/type_likes.svg" alt="likes"/></div> Likes</li>
        <li class="comments"><div class="legend_icon"><img src="@/assets/icons/type_comments.svg" alt="comments"/></div> Comments</li>
        <li class="shares"><div class="legend_icon"><img src="@/assets/icons/type_shares.svg" alt="shares"/></div> Shares</li>
        <li class="posts"><div class="legend_icon"><img src="@/assets/icons/type_posts.svg" alt="posts"/></div> Posts</li>
        <li class="emails"><div class="legend_icon"><img src="@/assets/icons/type_emails.svg" alt="emails"/></div> Emails</li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ContentType',
  data () {
    return {
      show: true
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  max-height: 0px;
  padding: 0;
  opacity: 0;
}

ul {
  margin: 0;
  max-height: 300px;
  list-style: none;
  padding: 21px 0 7px;
  opacity: 1;
  overflow: hidden;
}
ul li {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  margin: 0;
  margin-bottom: 16px;
  padding: 0;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
}
ul li .legend_icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sources {
  width: calc(100% - 54px);
  min-height: 50px;
  position: relative;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 27px;
  margin-bottom: 12px;
  padding: 12px 22px 0 30px;
}
.sources .arrow {
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  position: absolute;
  right: 22px;
}
.up {
  top: 23px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.down {
  top: 16px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.sources span {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  display: block;
  color: #333333;
  opacity: 0.8;
  cursor: pointer;
}
</style>
