<template>
  <div class="customer_profile">
    <div class="nav">
      <a href="#" class="back" @click.prevent="$router.go(-1)">
        <img src="@/assets/arr_left.svg" alt="back" />
        Back to All Customers
      </a>
    </div>
    <div class="customer_header">
      <h2>
        <img src="@/assets/favorite.svg" alt="favorite" />
        <span>{{ customer.name }}</span>
      </h2>
      <a href="#" @click.prevent="goToActivity()">VIEW {{ customer.name }}'S ACTIVITY</a>
    </div>
    <div class="customer_wrapper">
      <CustomerBlock
        v-if="customer.id"
        :customer="customer"
        :person="false"
      />
    </div>
    <div class="person_header">
      <h2 v-if="customer.contacts && customer.contacts !== []">
        CONTACT PERSONS &bull; <span>{{ customer.contacts.length }}</span>
      </h2>
      <a href="#" @click.prevent="add_new_person"><plusIcon /> ADD NEW CONTACT PERSON</a>
    </div>
    <div class="person_wrapper">
      <CustomerBlock
        v-for="contact in customer.contacts"
        :key="contact.id"
        :customer="contact"
        :person="true"
      />
    </div>
  </div>
</template>

<script>
import CustomerBlock from '@/components/profile/CustomerBlock.vue'
import plusIcon from '@/assets/icons/plus_icon.vue'
import { mapGetters, mapActions } from 'vuex'
import {
  SET_ADD_NEW_CONTACT_PERSON,
  UPDATE_ACTIVITY_CUSTOMER,
  FETCH_CUSTOMER_CONTACTS,
  FETCH_CUSTOMERS
} from '@/store/actions.type'

export default {
  name: 'CustomerProfile',
  props: {
    id: String
  },
  components: {
    plusIcon,
    CustomerBlock
  },
  created () {
    if (this.api_customers.length) {
      this.customer = this.api_customers.find(cust => {
        return cust.id === parseInt(this.id)
      })
      this.fetchContacts(this.customer.id)
    } else {
      this.fetchCustomers()
    }
  },
  computed: {
    ...mapGetters([
      'api_customers',
      'user_auth_token',
      'activity_customer_id',
      'addContactPerson'
    ])
  },
  data () {
    return {
      customer: {}
    }
  },
  watch: {
    activity_customer_id () {
      this.customer = this.api_customers.find(cust => {
        return cust.id === parseInt(this.id)
      })
      this.fetchContacts(this.customer.id)
    }
  },
  methods: {
    ...mapActions({
      setAddPersonPopup: SET_ADD_NEW_CONTACT_PERSON,
      setActivityCustomer: UPDATE_ACTIVITY_CUSTOMER,
      fetchContacts: FETCH_CUSTOMER_CONTACTS,
      fetchCustomers: FETCH_CUSTOMERS
    }),
    add_new_person () {
      this.setAddPersonPopup({ for_customer: this.customer })
    },
    goToActivity () {
      if (this.activity_customer_id !== parseInt(this.id)) {
        this.setActivityCustomer(parseInt(this.id))
      }
      this.$router.push('/activity')
    },
    fetchCustomerContacts () {
      this.customer = this.api_customers.find(cust => {
        return cust.id === parseInt(this.id)
      })
      this.fetchContacts(this.customer.id)
    }
  }
}
</script>

<style>
.customer_profile {}
.customer_profile .person_header,
.customer_profile .person_wrapper,
.customer_profile .customer_header,
.customer_profile .customer_wrapper {
  margin: 0 80px;
}
.customer_profile .person_header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.customer_profile .person_header h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #11263C;
  padding: 0;
  margin: 0;
}
.customer_profile .person_header h2 span {
  font-weight: 700;
}
.customer_profile .person_header a svg {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 15px 0 0;
  display: block;
}
.customer_profile .person_header a {
  height: 50px;
  background: #1CB1B8;
  border: 1px solid #1CB1B8;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 20px;
  text-decoration: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #FFFFFF;
  transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.customer_profile .person_header a:hover {
  background: #FFFFFF;
  color: #1CB1B8;
}

.customer_profile .person_header a:hover svg path {
  stroke: #1CB1B8;
}

.customer_profile .person_wrapper,
.customer_profile .customer_wrapper {
  margin-bottom: 30px;
}
.customer_profile .customer_header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.customer_profile .customer_header h2 {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center
}
.customer_profile .customer_header h2 img {
  margin: 0 25px 0 0;
}
.customer_profile .customer_header h2 span {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #11263C;
  display: block;
}
.customer_profile .customer_header a {
  height: 50px;
  display: block;
  box-sizing: border-box;
  background: #1CB1B8;
  border: 1px solid #1CB1B8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px 46px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #FFFFFF;
  text-decoration: none;
  transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.customer_profile .customer_header a:hover {
  background: #FFFFFF;
  color: #1CB1B8;
}

/* --- */

.customer_profile .nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.customer_profile a.back {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  color: #858585;
  text-decoration: none;
  margin: 30px 0 60px;
}
.customer_profile a.back:hover {
  text-decoration: underline;
}
.customer_profile a.back img {
  margin: 0 20px 0 40px;
}
</style>
