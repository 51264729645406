<template>
  <div id="customers">
    <SideBar />
    <div class="content">
      <div class="header">
        <span>ALL CUSTOMERS &bull; {{ api_customers.length }}</span>
        <button
          @click="add_customer"
          class="add_customer"
        >ADD NEW CUSTOMER</button>
      </div>
      <Customer
        v-for="user in api_customers"
        :key="`api_customers-${user.id}`"
        :user="user"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import {
  FETCH_CUSTOMERS
} from '@/store/actions.type'

import SideBar from '@/components/customers/SideBar.vue'
import Customer from '@/components/customers/Customer.vue'

export default {
  name: 'Customers',
  components: {
    SideBar,
    Customer
  },
  computed: {
    ...mapGetters([
      'customers',
      'api_customers',
      'user_auth_token'
    ])
  },
  data () {
    return {
    }
  },
  created () {
    this.fetchCustomers()
  },
  methods: {
    ...mapActions({
      fetchCustomers: FETCH_CUSTOMERS
    }),
    add_customer () {
      console.log('add customer')
    }
  }
}
</script>

<style scoped>
  #customers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 100vh;
  }
  #customers .content {
    width: 100%;
    padding: 43px 62px 30px 48px;
  }
  #customers .content .header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  #customers .content .header span {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;

    letter-spacing: 0.08em;
    text-transform: uppercase;

    color: #11263C;
  }
  #customers .content .header button {
    padding: 14px 26px 14px 52px;
    background: #1CB1B8;
    border: 1px solid #1CB1B8;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #FFFFFF;

    transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  #customers .content .header button:hover {
    background: #FFFFFF;
    color: #1CB1B8;
  }
  #customers .content .header button:after {
    content: '';
    display: inline-block;
    width: 13px;
    height: 13px;
    background: linear-gradient(hsl(0deg 0% 100%) 0 0), linear-gradient(hsl(0deg 0% 100%) 0 0), hsl(0deg 0% 0% / 0%);
    background-position: center;
    background-size: 100% 1px,1px 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: 20px;
    top: calc(50% - 6px);
  }
  #customers .content .header button:hover:after {
    background: linear-gradient(#1CB1B8 0 0), linear-gradient(#1CB1B8 0 0), hsl(0deg 0% 0% / 0%);
    background-position: center;
    background-size: 100% 1px,1px 100%;
    background-repeat: no-repeat;
  }
</style>
