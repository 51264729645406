<template>
  <div
    :class="{ customers_list: true, active: show }"
  >
    <span @click="show = !show">
      Change Customer
      <i
        class="arrow up"
        :class="{down: !show, up: show}"
      />
    </span>
    <transition name="fade">
      <ul v-if="show">
        <li class="search">
          <img
            src="@/assets/icons/search.svg"
            alt="search"
            @click="search"
          >
          <input type="text" placeholder="SEARCH CUSTOMER">
        </li>
        <li
          v-for="user in api_customers.slice(0,3)"
          :key="user.id"
          class="customer"
          :class="{ current: user.id == activity_customer_id }"
          @click="change_customer(user.id)"
        >
          <img
            v-if="user.logo"
            :src="user.logo"
            @load="onImgLoad"
            @error="imgUrlWrong"
            alt="Photo">
          <span>{{ user.name }}</span>
        </li>
        <li
          class="all_customers"
          @click="$router.push('/customers')"
        >
        VIEW ALL CUSTOMERS</li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  UPDATE_ACTIVITY_CUSTOMER,
  UPDATE_ACTIVITY_SHOWING_MESSAGES
} from '@/store/actions.type'

export default {
  name: 'Customers List',
  data () {
    return {
      show: false
    }
  },
  computed: {
    ...mapGetters([
      'activity_customer_id',
      'customers',
      'api_customers',
      'user_photo'
    ])
  },
  methods: {
    ...mapActions({
      setActivityCustomer: UPDATE_ACTIVITY_CUSTOMER,
      clearShowingMessages: UPDATE_ACTIVITY_SHOWING_MESSAGES
    }),
    imgUrlWrong (event) {
      event.target.style.display = 'none'
    },
    onImgLoad (event) {
      event.target.classList.add('loaded')
    },
    search () {
      console.log('search')
    },
    change_customer (id) {
      if (id !== this.activity_customer_id) {
        this.setActivityCustomer(id)
        this.clearShowingMessages({ date_range: '', messages: [] })
      }
      this.show = false
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  max-height: 0px;
  padding: 0;
  opacity: 0;
}

ul {
  margin: 0;
  max-height: 1000px;
  list-style: none;
  padding: 21px 0 7px;
  opacity: 1;
  overflow: hidden;
}
ul li {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  margin: 0 28px 16px 20px;
  position: relative;
}

ul li.search {
  box-sizing: border-box;
  position: relative;
}
ul li.search img {
  position: absolute;
  width: 22px;
  height: 22px;
  left: 10px;
  top: 15px;
  cursor: pointer;
}
ul li.search input {
  border: none;
  width: 100%;
  display: block;
  height: 48px;
  outline: 0;
  border-radius: 10px;
  padding: 14px 25px 12px 50px;
  box-sizing: border-box;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.08em;
  color: #858585;
  border-radius: 40px;
  border: 1px solid #EDEDED;
}
ul li.search input::placeholder {
  color: hsl(0deg 0% 52% / 80%);
}

ul li.customer {
  height: 50px;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 10px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
ul li.customer:hover {
  border-color: #1CB1B8;
}
ul li.customer:hover span {
  font-size: 15px;
  line-height: 18px;
}
ul li.customer.current {
  background-color: #1CB1B8;
  border-color: #1CB1B8;
}
ul li.customer.current span {
  color: #fff;
}
ul li.customer img {
  object-fit: cover;
  width: 40px;
  height: 40px;
  margin: 5px 16px 5px 0px;
  border-radius: 4px;
}
ul li.customer span {
  padding: 0;
  margin: 0 0 0 10px;
}

ul li.all_customers {
  font-size: 12px;
  color: #1CB1B8;

  line-height: 47px;
  padding: 0;
  margin: 0 18px 16px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
}
ul li.all_customers:hover {
  background-color: #1CB1B8;
  color: #fff;
}

.customers_list {
  width: 275px;
  min-height: 50px;
  position: relative;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0;
  margin-right: 5px;
  padding: 12px 0 0;
  background-color: #fff;
  z-index: 2;
}
.customers_list.active {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.customers_list span {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  display: block;
  color: #333333;
  opacity: 0.8;
  cursor: pointer;
  margin: 0;
  padding: 0 22px 0 30px;
}
.customers_list .arrow {
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  position: absolute;
  right: 22px;
}

.up {
  top: 23px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.down {
  top: 16px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
</style>
