import { createStore } from 'vuex'

import activity from '@/store/activity.module'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    activity
  }
})
