<template>
  <form
    id="signup"
    v-on:submit.prevent="submit"
    action="#"
  >
    <div>
      <h3>Sign up</h3>
      <p>to sell with consistency</p>
    </div>

    <div class="first_name_wrapper wrapper">
      <img src="@/assets/icons/user.svg" class="first" alt="user">
      <input v-model="first_name" class="first_name" type="text" placeholder="First Name" required />
    </div>
    <div class="last_name_wrapper wrapper">
      <img src="@/assets/icons/user.svg" class="first" alt="user">
      <input v-model="last_name" class="last_name" type="text" placeholder="Last Name" required />
    </div>

    <div class="email_wrapper wrapper">
      <img src="@/assets/icons/mail.svg" class="first" alt="mail">
      <input v-model="email" class="email" type="email" placeholder="Email Address" required />
    </div>
    <div class="password_wrapper wrapper">
      <img src="@/assets/icons/pass.svg" class="first" alt="password">
      <input v-model="password" class="password" :type="type" placeholder="Enter password" required />
      <img
        src="@/assets/icons/Eye_Off.svg"
        class="hide"
        alt="hide"
        @click="change_hide"
      >
    </div>
    <p v-if="error" class="error" v-html="error"></p>
    <button class="submit" type="submit">SIGN UP</button>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  SIGN_UP_CUSTOMER,
  CLEAR_CUSTOMER_TOKEN
} from '@/store/actions.type'

export default {
  name: 'SignUp',
  data () {
    return {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      type: 'password',
      error: ''
    }
  },
  computed: {
    ...mapGetters([
      'customers',
      'api_customers',
      'user_auth_token'
    ])
  },
  created () {
    this.clearToken()
  },
  methods: {
    ...mapActions({
      signUpCustomer: SIGN_UP_CUSTOMER,
      clearToken: CLEAR_CUSTOMER_TOKEN
    }),
    submit () {
      this.signUpCustomer({
        email: this.email,
        username: `${this.first_name} ${this.last_name}`,
        password: this.password
      }).then(
        () => {
          this.$router.push('/home')
        },
        (error) => {
          if (error.status === 400) {
            this.error = ''
            for (const [key, value] of Object.entries(error.data)) {
              this.error += `${value} <br />`
              console.log(`${key}: ${value}`)
            }
          }
        }
      )
    },
    change_hide () {
      this.type = this.type === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style scoped>
#signup {
  display: block;
  width: 350px;
  margin: 75px auto;
  padding: 0;
}
#signup p,
#signup h3 {
  font-family: Poppins;
  font-style: normal;
  letter-spacing: 0.01em;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin: 0;
}
#signup h3 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
}
#signup p {
  font-weight: 300;
  font-size: 14px;
  margin: 5px 0 10px;
}
#signup .wrapper {
  position: relative;
  margin: 0;
  margin-top: 15px;
}

#signup .wrapper img.first {
  display: block;
  position: absolute;
  top: 13px;
  left: 16px;
}
#signup .password_wrapper img.hide {
  display: block;
  position: absolute;
  top: 13px;
  right: 16px;
}

#signup input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  background: #FAFAFA;
  border: 0.5px solid #E4E4E4;
  box-shadow: inset 0px 1px 1px rgba(16, 32, 89, 0.12);
  border-radius: 5px;
  padding: 13px 0 13px 52px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #6F758B;
  outline: none;
}
#signup button.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  width: 100%;
  height: 50px;
  background: #1CB1B8;
  border: 1px solid #1CB1B8;
  box-sizing: border-box;
  border-radius: 100px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #F9FAFF;
  /* border: none; */
  margin: 0;
  margin-top: 15px;
  outline: none;
  cursor: pointer;

  transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
#signup button.submit:hover {
  background: #F9FAFF;
  color: #1CB1B8;
}
#signup p.error {
  color: hsl(0deg 100% 50%);
  font-weight: 400;
  text-align: left;
  margin: 0;
}
</style>
