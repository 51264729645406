<template>
  <div class="sources">
    <span @click="show = !show">
      Color Legend
      <i
        class="arrow up"
        :class="{down: !show, up: show}"
      />
    </span>
    <transition name="fade">
      <ul v-if="show">
        <li class="team"><div class="legend_icon"><img src="@/assets/icons/legend_team.svg" alt="linkedin"/></div> Team's Activity</li>
        <li class="linkedin"><div class="legend_icon"><img src="@/assets/icons/legend_linkedin.svg" alt="linkedin"/></div> Linkedin</li>
        <li class="salesforce"><div class="legend_icon"><img src="@/assets/icons/legend_salesforce.svg" alt="salesforce"/></div> Salesforce</li>
        <!-- <li class="twitter"><div class="legend_icon"><img src="@/assets/icons/legend_twitter.svg" alt="twitter"/></div> Twitter</li> -->
        <!-- <li class="chorus"><div class="legend_icon"><img src="@/assets/icons/legend_chorus.svg" alt="chorus"/></div> Chorus.ai</li> -->
        <li class="email"><div class="legend_icon"><img src="@/assets/icons/legend_emails.svg" alt="email"/></div> Emails</li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ColorLegend',
  data () {
    return {
      show: true
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  max-height: 0px;
  padding: 0;
  opacity: 0;
}

ul {
  margin: 0;
  max-height: 300px;
  list-style: none;
  padding: 21px 0 7px;
  opacity: 1;
  overflow: hidden;
}
ul li {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  margin: 0;
  margin-bottom: 16px;
  padding: 0;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
}
ul li .legend_icon {
  height: 30px;
  width: 30px;
  position: relative;
  margin: 0 10px 0 0;
  padding: 0 0 0 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
ul li.team .legend_icon:after {
  background-color: #9F6BE1;
}
ul li.linkedin .legend_icon:after {
  background-color: #1568BF;
}
ul li.salesforce .legend_icon:after {
  background-color: #00A1E0;
}
ul li.twitter .legend_icon:after {
  background-color: #18D3DF;
}
ul li.chorus .legend_icon:after {
  background-color: #4085E6;
}
ul li.email .legend_icon:after {
  background-color: #EA4335;
}
ul li .legend_icon:after {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0px;
  top: 0px;
  box-sizing: border-box;
  border-radius: 50%;
  z-index: -1;
}
.sources {
  width: calc(100% - 54px);
  min-height: 50px;
  position: relative;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 27px;
  margin-bottom: 12px;
  padding: 12px 22px 0 30px;
}
.sources img {
  width: 24px;
  height: 24px;
  display: block;
  box-sizing: border-box;
  object-fit: none;
}
.sources .chorus img {
  margin-right: 1px;
}
.sources .arrow {
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  position: absolute;
  right: 22px;
}
.up {
  top: 23px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.down {
  top: 16px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.sources span {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  display: block;
  color: #333333;
  opacity: 0.8;
  cursor: pointer;
}
</style>
