<template>
  <div class="side">
    <Search />
    <Filters />
    <Stage />
    <!-- <DateFilter /> -->
  </div>
</template>

<script>
import Search from '@/components/customers/side/Search.vue'
import Filters from '@/components/customers/side/Filters.vue'
import Stage from '@/components/customers/side/Stages.vue'
// import DateFilter from '@/components/customers/side/Dates.vue'

export default {
  name: 'CustomersSideBar',
  components: {
    Search,
    Filters,
    // DateFilter,
    Stage
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .side {
    max-width: 330px;
    width: 100%;

    padding: 25px 0;
    background-color: rgba(196, 196, 196, 0.12);

    align-self: stretch;
  }
</style>
