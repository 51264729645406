<template>
  <form
    id="forgot"
    v-on:submit.prevent="submit"
    action="#"
  >
    <div>
      <h3>Forgot password</h3>
      <p>We will send you a link to reset your password</p>
    </div>

    <div class="email_wrapper">
      <img src="@/assets/icons/mail.svg" alt="mail">
      <input
        v-model="email"
        class="email"
        type="email"
        placeholder="Email Address"
        required
        @input="error = ''"
      />
      <p v-if="error" class="error">{{ error }}</p>
    </div>
    <button class="submit" type="submit">reset password</button>
    <span class="separator">
      <span>OR</span>
    </span>
    <router-link
      class="link"
      to="/sign-in"
    >SIGN IN</router-link>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Forgot',
  data () {
    return {
      email: '',
      error: ''
    }
  },
  computed: {
    ...mapGetters([
      'customers',
      'api_customers',
      'user_auth_token'
    ])
  },
  methods: {
    submit () {
      if (this.email.length === 0) {
        this.error = "Email can't be empty."
      } else if (!this.validateEmail()) {
        this.error = 'Please, enter a valid email address'
      } else {
        this.$router.push('/sign-in')
      }
    },
    validateEmail () {
      return String(this.email).toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
  }
}
</script>

<style scoped>
#forgot {
  display: block;
  width: 350px;
  margin: 90px auto;
  padding: 0;
}
#forgot p.error {
  color: hsl(0deg 100% 50%);
  font-weight: 400;
  text-align: left;
  margin: 0;
}
#forgot p,
#forgot h3 {
  font-family: Poppins;
  font-style: normal;
  letter-spacing: 0.01em;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin: 0;
}
#forgot h3 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
}
#forgot p {
  font-weight: 300;
  font-size: 14px;
  margin: 5px 0 10px;
}
#forgot .email_wrapper {
  position: relative;
  margin: 0;
  margin-bottom: 25px;
}
#forgot .email_wrapper img {
  display: block;
  position: absolute;
  top: 13px;
  left: 16px;
}
#forgot input.email {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  background: #FAFAFA;
  border: 0.5px solid #E4E4E4;
  box-shadow: inset 0px 1px 1px rgba(16, 32, 89, 0.12);
  border-radius: 5px;
  padding: 13px 0 13px 52px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #6F758B;
  outline: none;
}
#forgot button.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  width: 100%;
  height: 50px;
  background: #1CB1B8;
  border-radius: 100px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #F9FAFF;
  border: 1px solid #1CB1B8;
  box-sizing: border-box;
  margin: 0;
  cursor: pointer;
  outline: none;
  transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
#forgot button.submit:hover {
  background: #F9FAFF;
  color: #1CB1B8;
}
#forgot .link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  width: 100%;
  height: 50px;
  background: #FAFAFA;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 30px;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #1CB1B8;
  text-decoration: none;
  transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
#forgot .link:hover {
  background: #1CB1B8;
  border: 1px solid #DADADA;
  color: #FAFAFA;
}
#forgot span span {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 48px;
  background: #FFFFFF;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: rgba(111, 117, 139, 0.3);
}
#forgot span span:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  background-color: rgba(111, 117, 139, 0.1);
  width: 100%;
  height: 1px;
  z-index: -1;
}
#forgot span.separator {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
</style>
