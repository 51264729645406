<template>
  <div class="contact">
    <img
      v-if="contact.photo"
      :src="contact.photo"
      @load="onImgLoad"
      @error="imgUrlWrong"
      alt="Photo">
    <p class="contact_name">{{ full_name }}</p>
    <p class="contact_position">{{ contact.role }}</p>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  props: {
    contact: Object
  },
  computed: {
    full_name () {
      return `${this.contact.first_name} ${this.contact.last_name}`
    }
  },
  methods: {
    imgUrlWrong (event) {
      event.target.style.display = 'none'
    },
    onImgLoad (event) {
      event.target.classList.add('loaded')
    }
  }
}
</script>

<style scoped>
  .contact {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    max-width: 160px;
    box-sizing: border-box;
  }
  .contact img.loaded {
    object-fit: cover;
    width: 64px;
    height: 64px;
    border-radius: 55px;
    margin: 0;
    margin-bottom: 5px;
  }
  .contact p {
    font-family: Poppins;
    font-style: normal;
    /* font-size: 14px; */
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    margin: 0;
  }
  .contact .contact_name {
    font-weight: 600;
    color: #333333;
    margin-bottom: 5px;
  }
  .contact .contact_position {
    font-weight: normal;
    color: #858585;
  }
</style>
