<template>
  <p :class="['stage', getStage]">
    {{ getStage }}
  </p>
</template>

<script>
export default {
  name: 'Stage Badge',
  props: {
    stage: String
  },
  computed: {
    getStage () {
      let stage = ''
      switch (this.stage) {
        case 'OB':
          stage = 'Onboarding'
          break
        case 'OD':
          stage = 'Onboarded'
          break
        case 'EN':
          stage = 'Engaged'
          break
        case 'RE':
          stage = 'Renewal'
          break
        case 'RD':
          stage = 'Renewed'
          break
        default:
          stage = 'empty'
      }
      return stage.toLowerCase()
    }
  }
}
</script>

<style scoped>
p.stage {
  border-radius: 51px;
  width: 100%;
  padding: 3px 0 2px;
  margin-bottom: 7px;

  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #FFFFFF;
}
p.stage.empty {
  color: #000;
  border: 1px solid #000;
}
.onboarding {
  background-color: #65DE62;
}
.onboarded {
  background-color: #40CFD8;
}
.engaged {
  background-color: #A2A7F3;
}
.renewal {
  background-color: #F688F9;
}
.renewed {
  background-color: #9F6BE1;
}
</style>
