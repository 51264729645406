<template>
  <div
    v-if="this.api_customers.length > 0"
    id="activity"
  >
    <SideBar />
    <TimeLine />
    <MessagesBar />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  UPDATE_ACTIVITY_CUSTOMER,
  FETCH_CUSTOMER_ACTIVITY,
  FETCH_CUSTOMERS
} from '@/store/actions.type'

import SideBar from '@/components/activity/SideBar.vue'
import TimeLine from '@/components/activity/TimeLine.vue'
import MessagesBar from '@/components/activity/MessagesBar.vue'

export default {
  name: 'Activity',
  components: {
    TimeLine,
    SideBar,
    MessagesBar
  },
  computed: {
    ...mapGetters([
      'activity_customer_id',
      'activity_customer',
      'customers',
      'api_customers',
      'user_auth_token'
      // 'active_date_interval'
    ])
  },
  watch: {
    activity_customer_id () {
      this.fetchCustomerActivity()
    }
    // active_date_interval () {
    //   // this.fetchCustomerActivity()
    // }
  },
  created () {
    if (this.activity_customer_id === null) {
      if (this.api_customers.length) {
        this.setActivityCustomer(this.api_customers[0].id)
      } else {
        this.fetchCustomers()
      }
    } else {
      this.fetchCustomerActivity()
    }
  },
  methods: {
    ...mapActions({
      setActivityCustomer: UPDATE_ACTIVITY_CUSTOMER,
      fetchCustomerActivity: FETCH_CUSTOMER_ACTIVITY,
      fetchCustomers: FETCH_CUSTOMERS
    })
  }
}
</script>

<style scoped>
  #activity {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: calc(100vh - 81px);
  }
</style>
