import { API_URL } from './config.js'
import ApiService from '@/common/api.service'

export const CustomerService = {
  fetchCustomersList () {
    return ApiService.get(`${API_URL}customer/`)
  },
  fetchContacts (id) {
    return ApiService.get(`${API_URL}customer/${id}/contacts`)
  },
  addNewContactPerson (id, params) {
    return ApiService.post(`customer/${id}/contacts/`, params)
  },

  fetchCustomerActivity (id) {
    return ApiService.get(`customer/${id}/activity`)
  },
  activitySearch (id, query) {
    return ApiService.query(`customer/${id}/activity/`, query)
  },

  addNote (params) {
    return ApiService.post(`note/${params.kind}/`, {
      content: params.content,
      [params.kind]: params.id
    })
  },
  fetchNotes (params) {
    return ApiService.get(`note/${params.kind}/?${params.kind}=${params.id}`)
  },
  fetchSinglePost (id) {
    return ApiService.query(`${API_URL}post/${id}`)
  },

  fetchSalesforceLeads () {
    return ApiService.get('salesforce/lead')
  },
  fetchSalesforceContacts () {
    return ApiService.get('salesforce/contact')
  },
  fetchSalesforceOpportunitys () {
    return ApiService.get('salesforce/opportunity')
  },
  fetchSalesforceAccounts () {
    return ApiService.get('salesforce/account')
  }
}

export default CustomerService
