<template>
  <div class="note">
    <div class="note_container">
      <div class="note_head">
        <div class="left">
          <img src="@/assets/note_date.svg" alt="date">
          <span>{{ note_date }} &bull; {{ note_time }}</span>
        </div>
        <div v-if="false" class="right">
          <span>{{ note.name }}</span>
          <img src="@/assets/note_photo.png" alt="photo">
        </div>
      </div>
      <p class="note_content">{{ note.content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Note',
  props: {
    note: Object
  },
  computed: {
    note_date () {
      const created = new Date(this.note.created)
      const today = new Date()
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
      const options = { day: 'numeric', month: 'short', year: 'numeric' }
      const month = created.toLocaleDateString('en-US', options).split(' ')[0]
      if (created.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
        return 'Today'
      } else if (created.setHours(0, 0, 0, 0) === yesterday.setHours(0, 0, 0, 0)) {
        return 'Yesterday'
      } else {
        return `${('0' + created.getDate()).slice(-2)} ${month} ${created.getFullYear()} `
      }
    },
    note_time () {
      const created = new Date(this.note.created)

      return created.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    }
  }
}
</script>

<style scoped>
.note .note_container {
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
}
.note .note_container .note_content {
  margin: 0 30px 30px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
}

.note .note_container .note_head {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 10px 30px;
}
.note .note_container .note_head .right img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 0 0 15px;
  padding: 0;
  display: block;
}
.note .note_container .note_head .right span {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #333333;
}
.note .note_container .activity_head .right {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #858585;
}
.note .note_container .note_head .right,
.note .note_container .note_head .left {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
/* .note .note_container .activity_head .left img, */
.note .note_container .note_head .left img {
  margin: 0 10px 0 0;
  padding: 0;
}
.note .note_container .note_head .left span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #858585;
}

</style>
