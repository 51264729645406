<template>
  <div
    id="app_wrapper"
    :class="{ 'popup_open': note_for_id || all_notes.notes.length > 0 || isDateFilter || addContactPerson }"
  >
    <header class="main_header">
      <div class="logo" @click="$router.push('/home')">
        <img alt="App Logo" src="./assets/main_header_logo.png">
      </div>
      <div
        v-if="user_auth_token"
        class="nav main_menu">
        <router-link
          class="link"
          to="/home"
        >HOME</router-link>
        <router-link
          class="link"
          to="/customers"
        >CUSTOMERS</router-link>
        <router-link
          class="link"
          to="/activity"
        >ACTIVITY</router-link>
      </div>
      <div
        v-if="!user_auth_token"
        class="nav login"
      >
        <router-link
          class="link"
          to="/sign-in"
        >SIGN IN</router-link>
        <div class="line" />
        <router-link
          class="link"
          to="/sign-up"
        >SIGN UP</router-link>
      </div>
      <div
        v-else
        class="nav user"
      >
        <router-link
          to="/about"
          class="user-link"
        >
          <img alt="User Photo" :src="user.photo">
        </router-link>
      </div>
    </header>
    <!-- <router-view v-if="activity_customer_id !== null"/> -->
    <router-view />
    <Notes />
    <ShowNotes />
    <DateRange />
    <NewContactPerson />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  AUTH_CUSTOMER,
  FETCH_CUSTOMERS
} from '@/store/actions.type'

import Notes from '@/components/Notes.vue'
import ShowNotes from '@/components/ShowNotes.vue'
import DateRange from '@/components/activity/DateRange.vue'
import NewContactPerson from '@/components/NewContactPerson.vue'

export default {
  name: 'app',
  components: {
    Notes,
    ShowNotes,
    DateRange,
    NewContactPerson
  },
  data () {
    return {
      user_is_logged: true,
      user: {
        photo: require('./assets/profile.png')
      }
    }
  },
  computed: {
    ...mapGetters([
      'activity_customer_id',
      'user_auth_token',
      'note_for_id',
      'all_notes',
      'isDateFilter',
      'addContactPerson'
    ])
  },
  created () {
    const token = localStorage.getItem('token')
    if (!token) {
      this.$router.push('/sign-in')
    } else {
      this.authCustomer(token)
    }
  },
  methods: {
    ...mapActions({
      authCustomer: AUTH_CUSTOMER,
      fetchCustomers: FETCH_CUSTOMERS
    })
  }
}
</script>

<style>
@font-face {
  font-family: Poppins;
  src: url("./assets/fonts/Poppins-Regular.ttf");
  /* src: url("CustomFont.woff") format("woff"),
  url("CustomFont.otf") format("opentype"),
  url("CustomFont.svg#filename") format("svg"); */
  font-weight: normal;
}
@font-face {
  font-family: Poppins;
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: Poppins;
  src: url("./assets/fonts/Poppins-Thin.ttf");
  font-weight: 300;
}

body{
  margin: 0;
  padding: 0;
}
#app_wrapper {
  max-width: 1440px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
#app_wrapper.popup_open > * {
  filter: blur(3px);
}
header.main_header {
  height: 80px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
}
header.main_header .logo {
  padding: 20px 100px 20px 42px;
  cursor: pointer;
}
header.main_header .nav.login {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 67px;
}
header.main_header .nav.login .link {
  margin: 0;
  margin-right: 20px;
}
header.main_header .nav.login .link:last-child {
  margin: 0;
  margin-left: 20px;
}
header.main_header .nav.login .line {
  display: block;
  width: 1px;
  height: 30px;
  background-color: #D8D8D8;
  margin: 30px 0;
}
header.main_header .user {
  padding: 20px 60px 20px 0;
}
header.main_header .user .user-link {
  display: block;
  text-decoration: none;
  height: 100%;
  width: 100%;
}
header.main_header .user .user-link img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
header.main_header .nav.main_menu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
header.main_header .nav .link {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  text-decoration: none;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #858585;

  position: relative;
  height: 100%;
  width: fit-content;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  flex-flow: column;
  margin-right: 65px;
}
header.main_header .nav .link:last-child {
  margin: 0;
}
header.main_header .nav .link.router-link-active {
  color: #333;
}
header.main_header .nav .link.router-link-active:before {
  content: '';
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: 0;
  background-color: #1cb1b8;
  border-radius: 12px 12px 0 0;
}
</style>
