<template>
  <div class="dates" :style="{ maxWidth: `${wrapper_width}px` }">
    <!-- <transition name="fade"> -->
    <ul>
      <li :class="{ active: date_range === '14_day' }" :style="{ maxWidth: `${width}px` }">
        <input type="radio" id="14_day" @change="actve_order = 0" value="14_day" v-model="date_range">
        <label for="14_day">14 DAY</label>
      </li>
      <li :class="{ active: date_range === '1_mon' }" :style="{ maxWidth: `${width}px` }">
        <input type="radio" id="1_mon" @change="actve_order = 1" value="1_mon" v-model="date_range">
        <label for="1_mon">1 MON</label>
      </li>
      <li :class="{ active: date_range === '3_mon' }" :style="{ maxWidth: `${width}px` }">
        <input type="radio" id="3_mon" @change="actve_order = 2" value="3_mon" v-model="date_range">
        <label for="3_mon">3 MON</label>
      </li>
      <li :class="{ active: date_range === '6_mon' }" :style="{ maxWidth: `${width}px` }">
        <input type="radio" id="6_mon" @change="actve_order = 3" value="6_mon" v-model="date_range">
        <label for="6_mon">6 MON</label>
      </li>
      <li :class="{ active: date_range === '1_year' }" :style="{ maxWidth: `${width}px` }">
        <input type="radio" id="1_year" @change="actve_order = 4" value="1_year" v-model="date_range">
        <label for="1_year">1 YEAR</label>
      </li>
      <li
        class="for_active"
        :style="{ maxWidth: `${width}px`, transform: `translateX(${width * actve_order}px)` }"
      ></li>
    </ul>
    <!-- </transition> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  UPDATE_ACTIVITY_SHOWING_MESSAGES,
  UPDATE_ACTIVE_TYPE,
  UPDATE_DATE_INTERVAL,
  UPDATE_ACTIVE_POINT
} from '@/store/actions.type'

export default {
  name: 'Date Filter',
  components: {
  },
  computed: {
    ...mapGetters([
      'isDateFilter',
      'activityDateRangeFilter',
      'activity_customer'
    ])
  },
  data () {
    return {
      date_range: '14_day',
      actve_order: 0,
      width: 70,
      wrapper_width: 420
    }
  },
  watch: {
    date_range (val) {
      // this.updateDateInterval(val)
      const kind = val.indexOf('day') >= 0 ? 'days' : 'months'
      this.setActiveType({ kind: kind, month_id: null, date_range: val })
      this.clearShowingMessages({ date_range: '', messages: [], kind: '' })
      this.setPoint('')
    },
    activity_customer () {
      this.actve_order = 0
      this.date_range = '14_day'
    }
  },
  methods: {
    ...mapActions({
      clearShowingMessages: UPDATE_ACTIVITY_SHOWING_MESSAGES,
      updateDateInterval: UPDATE_DATE_INTERVAL,
      setActiveType: UPDATE_ACTIVE_TYPE,
      setPoint: UPDATE_ACTIVE_POINT
    })
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  max-height: 0px;
  padding: 0;
  opacity: 0;
}
.dates {
  width: 100%;
  min-height: 50px;
  position: relative;
  border: 0;
  border-bottom: 1px solid #EDEDED;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  max-height: 300px;
  list-style: none;
  padding: 0;
  opacity: 1;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
ul li {
  width: 100%;
  transition: transform .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
ul li.for_active {
  position: absolute;
  height: 4px;
  background: #1CB1B8;
  border-radius: 12px;
  bottom: 0;
  left: 0;
}
ul li label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #858585;
  line-height: 30px;
  display: block;
  box-sizing: border-box;
  width: 60px;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
}
ul li:hover label,
ul li.active label {
  font-weight: 600;
}
ul li input {
  display: none;
}
</style>
