// Activity
export const UPDATE_ACTIVITY_CUSTOMER = 'updateActivityCustomer'
export const UPDATE_ACTIVITY_SHOWING_MESSAGES = 'updateActivityShowingMessages'
export const FETCH_CUSTOMERS = 'fetchCustomers'
export const FETCH_CUSTOMER_ACTIVITY = 'fetchCustomerActivity'
export const FETCH_CUSTOMER_CONTACTS = 'fetchCustomerContacts'
export const UPDATE_ACTIVE_LINE = 'updateActiveLine'
export const UPDATE_ACTIVE_POINT = 'updateActivePoint'
export const UPDATE_ACTIVE_TYPE = 'updateActiveType'
export const FETCH_SINGLE_POST = 'fetchSinglePost'
export const TOGGLE_ACTIVITY_DATE_FILTER = 'toggleActivityDateFilter'
export const UPDATE_DATE_INTERVAL = 'updateDateInterval'
export const ACTIVITY_SEARCH = 'activitySearch'
export const UPDATE_ACTIVITY_SEARCH_QUERY = 'updateActivitySearchQuery'
// Customer
// Auth
export const AUTH_CUSTOMER = 'authCustomer'
export const SIGN_IN_CUSTOMER = 'signInCustomer'
export const SIGN_UP_CUSTOMER = 'signUpCustomer'
export const CLEAR_CUSTOMER_TOKEN = 'clearCustomerToken'
// Note
export const ADD_NOTE = 'addNote'
export const FETCH_NOTES = 'fetchNotes'
export const UPDATE_NOTE_TARGET = 'updateNoteTarget'
export const SHOW_NOTES = 'showNotes'
// Customer Profile
export const SET_ADD_NEW_CONTACT_PERSON = 'setAddNewContactPerson'
export const ADD_NEW_CONTACT_PERSON = 'addNewContactPerson'
