import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Customers from '../views/Customers.vue'
import CustomerProfile from '../views/CustomerProfile.vue'
import Activity from '../views/Activity.vue'
import Forgot from '../views//auth/Forgot.vue'
import SignIn from '../views//auth/SignIn.vue'
import SignUp from '../views//auth/SignUp.vue'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/customer_profile/:id',
    name: 'CustomerProfile',
    component: CustomerProfile,
    props: true
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/activity',
    name: 'Activity',
    component: Activity
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  const alwaysAvailable = ['SignIn', 'SignUp', 'Forgot']
  if (alwaysAvailable.indexOf(to.name) < 0) {
    if (!localStorage.getItem('token')) {
      router.push('/sign-in')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
