// Activity
export const SET_ACTIVITY_CUSTOMER = 'setActivityCustomer'
export const SET_ACTIVITY_SHOWING_MESSAGES = 'setActivityShowingMessages'
export const SET_ACTIVITY_KEYWORDS_FILTER = 'setActivityKeywordsFilter'
export const SET_CUSTOMERS = 'setCustomers'
export const UPDATE_CUSTOMER_LINE = 'updateCustomerLine'
export const SET_ACTIVITY_PRESETS = 'setActivityPresets'
export const SET_AUTH_TOKEN = 'setAuthToken'
export const SET_CUSTOMER_CONTACTS = 'setCustomerContacts'
export const SET_NOTE_TARGET = 'setNoteTarget'
export const SET_SHOW_NOTES = 'setShowNotes'
