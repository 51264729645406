<template>
  <form
    id="signin"
    v-on:submit.prevent="submit"
    action="#"
  >
    <div>
      <h3>Sign in</h3>
      <p>to sell with consistency</p>
    </div>

    <div class="email_wrapper">
      <img src="@/assets/icons/mail.svg" class="first" alt="mail">
      <input
        required
        v-model="email"
        class="email"
        type="email"
        placeholder="Email Address" />
    </div>
    <div class="password_wrapper">
      <img src="@/assets/icons/pass.svg" class="first" alt="password">
      <input
        required
        v-model="password"
        class="password"
        :type="type"
        placeholder="Enter password"/>
      <img
        src="@/assets/icons/Eye_Off.svg"
        class="hide"
        alt="hide"
        @click="change_hide"
      >
    </div>
    <p v-if="error" class="error">{{ error }}</p>
    <div class="router_wrapper">
      <router-link class="link" to="/forgot" >Forgot Password</router-link>
    </div>
    <button class="submit" type="submit">SIGN IN</button>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import {
  SIGN_IN_CUSTOMER,
  CLEAR_CUSTOMER_TOKEN
} from '@/store/actions.type'

export default {
  name: 'SignIn',
  data () {
    return {
      email: 'admin@mail.com',
      password: 'AeFi8Cha8osie7U',
      type: 'password',
      error: ''
    }
  },
  computed: {
    ...mapGetters([
      'user_auth_token'
    ])
  },
  created () {
    this.clearToken()
  },
  methods: {
    ...mapActions({
      signInCustomer: SIGN_IN_CUSTOMER,
      clearToken: CLEAR_CUSTOMER_TOKEN
    }),
    submit () {
      this.signInCustomer({
        username: this.email,
        password: this.password
      }).then(
        () => this.$router.push('/home'),
        (data) => {
          if (data.status === 400) {
            this.error = 'Wrong email or password'
          }
        }
      )
    },
    change_hide () {
      this.type = this.type === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style scoped>
#signin {
  display: block;
  width: 350px;
  margin: 75px auto;
  padding: 0;
}
#signin p,
#signin h3 {
  font-family: Poppins;
  font-style: normal;
  letter-spacing: 0.01em;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin: 0;
}
#signin h3 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
}
#signin p {
  font-weight: 300;
  font-size: 14px;
  margin: 5px 0 10px;
}
#signin .password_wrapper,
#signin .email_wrapper {
  position: relative;
  margin: 0;
  margin-top: 15px;
}

#signin .password_wrapper img.first,
#signin .email_wrapper img.first {
  display: block;
  position: absolute;
  top: 13px;
  left: 16px;
}
#signin .password_wrapper img.hide {
  display: block;
  position: absolute;
  top: 13px;
  right: 16px;
}

#signin input.email,
#signin input.password {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  background: #FAFAFA;
  border: 0.5px solid #E4E4E4;
  box-shadow: inset 0px 1px 1px rgba(16, 32, 89, 0.12);
  border-radius: 5px;
  padding: 13px 0 13px 52px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #6F758B;
  outline: none;
}
#signin button.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  width: 100%;
  height: 50px;
  background: #1CB1B8;
  border-radius: 100px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #F9FAFF;
  border: 1px solid #1CB1B8;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  cursor: pointer;

  transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
#signin button.submit:hover {
  background: #F9FAFF;
  color: #1CB1B8;
}
#signin .router_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
#signin .link {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #1CB1B8;

  font-size: 14px;
  line-height: 34px;
  text-align: right;
  margin: 18px 0;
  text-decoration: none;
}
#signin p.error {
  color: hsl(0deg 100% 50%);
  font-weight: 400;
  text-align: left;
  margin: 0;
}
</style>
