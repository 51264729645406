<template>
  <div class="filters">
    <span>Filters</span>
    <img src="@/assets/icons/filters.svg" alt="filters">
  </div>
</template>

<script>
export default {
  name: 'Filters'
}
</script>

<style scoped>
.filters {
  width: calc(100% - 54px);
  height: 50px;
  position: relative;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 27px;
  margin-bottom: 12px;
  padding: 12px 30px;
}
.filters img {
  position: absolute;
  right: 16px;
  top: calc(50% - 12px);
}
.filters span {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */
  color: #333333;
  opacity: 0.8;
}
</style>
