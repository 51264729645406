<template>
  <div class="home">
    <FirstTime
      v-if="show_popup"
      @hide="show_popup = false"
    />
    <img src="@/assets/test/dashboard.png" class="fake" alt="fake">
    <!-- <FavoriteCustomers /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import FirstTime from '@/components/home/FirstTime.vue'
// import FavoriteCustomers from '@/components/home/FavoriteCustomers.vue'

export default {
  name: 'Home',
  components: {
    // FavoriteCustomers,
    FirstTime
  },
  computed: {
    ...mapGetters([
      'user_auth_token'
    ])
  },
  data () {
    return {
      show_popup: true
    }
  }
}
</script>
<style scoped>
.home img.fake {
  max-width: 1440px;
  width: 100%;
  height: auto;
}
</style>
