import {
  UPDATE_ACTIVITY_CUSTOMER,
  UPDATE_ACTIVITY_SHOWING_MESSAGES,
  AUTH_CUSTOMER,
  FETCH_CUSTOMERS,
  FETCH_CUSTOMER_ACTIVITY,
  FETCH_CUSTOMER_CONTACTS,
  CLEAR_CUSTOMER_TOKEN,
  SIGN_IN_CUSTOMER,
  SIGN_UP_CUSTOMER,
  ADD_NOTE,
  UPDATE_ACTIVE_LINE,
  UPDATE_ACTIVE_TYPE,
  UPDATE_NOTE_TARGET,
  FETCH_NOTES,
  SHOW_NOTES,
  FETCH_SINGLE_POST,
  UPDATE_ACTIVE_POINT,
  TOGGLE_ACTIVITY_DATE_FILTER,
  SET_ADD_NEW_CONTACT_PERSON,
  ADD_NEW_CONTACT_PERSON,
  UPDATE_DATE_INTERVAL,
  ACTIVITY_SEARCH,
  UPDATE_ACTIVITY_SEARCH_QUERY
} from '@/store/actions.type.js'
import {
  SET_ACTIVITY_CUSTOMER,
  SET_ACTIVITY_SHOWING_MESSAGES,
  SET_ACTIVITY_KEYWORDS_FILTER,
  SET_CUSTOMERS,
  SET_AUTH_TOKEN,
  UPDATE_CUSTOMER_LINE,
  SET_ACTIVITY_PRESETS,
  SET_CUSTOMER_CONTACTS,
  SET_NOTE_TARGET,
  SET_SHOW_NOTES
} from '@/store/mutations.type.js'
import { ApiService } from '@/common/api.service'
import { AuthService } from '@/common/auth.service'
import { CustomerService } from '@/common/customer.service'

const state = {
  // for empty fields
  user_photo: require('../assets/no_image.png'),
  //
  user_auth_token: '',
  activity_customer_id: null,
  activity_customer: null,
  activity_seach_query: '',
  activity_keywords_filter: {
    keywordsInclude: [],
    keywordsExclude: []
  },
  active_line: '',
  active_point: '',
  active_type: {
    kind: 'days',
    month_id: null,
    date_range: '14_day'
  },
  activity_showing_messages: {
    date_range: '',
    messages: []
  },
  api_customers: [],
  config: {
    '14_day': {
      month_height_px: '100',
      day_height_px: '100',
      month_points: 2,
      day_points: 2
    },
    '1_mon': {
      month_height_px: '600',
      day_height_px: '600',
      month_points: 10,
      day_points: 10
    },
    '3_mon': {
      month_height_px: '200',
      day_height_px: '200',
      month_points: 4,
      day_points: 4
    },
    '6_mon': {
      month_height_px: '120',
      day_height_px: '120',
      month_points: 2,
      day_points: 2
    },
    '1_year': {
      month_height_px: '50',
      day_height_px: '50',
      month_points: 1,
      day_points: 1
    }
  },
  active_date_interval: '',
  activity_scroll_to: '240px',
  months: [
    {
      id: 1,
      name: 'January',
      days: 31,
      active_days: [],
      lines: { linkedin: { activity_elements: {} }, gmail: { activity_elements: {} }, team: { activity_elements: {} }, salesforce: { activity_elements: {} } }
    },
    {
      id: 2,
      name: 'February',
      days: 29,
      active_days: [],
      lines: { linkedin: { activity_elements: {} }, gmail: { activity_elements: {} }, team: { activity_elements: {} }, salesforce: { activity_elements: {} } }
    },
    {
      id: 3,
      name: 'March',
      days: 31,
      active_days: [],
      lines: { linkedin: { activity_elements: {} }, gmail: { activity_elements: {} }, team: { activity_elements: {} }, salesforce: { activity_elements: {} } }
    },
    {
      id: 4,
      name: 'April',
      days: 30,
      active_days: [],
      lines: { linkedin: { activity_elements: {} }, gmail: { activity_elements: {} }, team: { activity_elements: {} }, salesforce: { activity_elements: {} } }
    },
    {
      id: 5,
      name: 'May',
      days: 31,
      active_days: [],
      lines: { linkedin: { activity_elements: {} }, gmail: { activity_elements: {} }, team: { activity_elements: {} }, salesforce: { activity_elements: {} } }
    },
    {
      id: 6,
      name: 'June',
      days: 30,
      active_days: [],
      lines: { linkedin: { activity_elements: {} }, gmail: { activity_elements: {} }, team: { activity_elements: {} }, salesforce: { activity_elements: {} } }
    },
    {
      id: 7,
      name: 'July',
      days: 31,
      active_days: [],
      lines: { linkedin: { activity_elements: {} }, gmail: { activity_elements: {} }, team: { activity_elements: {} }, salesforce: { activity_elements: {} } }
    },
    {
      id: 8,
      name: 'August',
      days: 31,
      active_days: [],
      lines: { linkedin: { activity_elements: {} }, gmail: { activity_elements: {} }, team: { activity_elements: {} }, salesforce: { activity_elements: {} } }
    },
    {
      id: 9,
      name: 'September',
      days: 30,
      active_days: [],
      lines: { linkedin: { activity_elements: {} }, gmail: { activity_elements: {} }, team: { activity_elements: {} }, salesforce: { activity_elements: {} } }
    },
    {
      id: 10,
      name: 'October',
      days: 31,
      active_days: [],
      lines: { linkedin: { activity_elements: {} }, gmail: { activity_elements: {} }, team: { activity_elements: {} }, salesforce: { activity_elements: {} } }
    },
    {
      id: 11,
      name: 'November',
      days: 30,
      active_days: [],
      lines: { linkedin: { activity_elements: {} }, gmail: { activity_elements: {} }, team: { activity_elements: {} }, salesforce: { activity_elements: {} } }
    },
    {
      id: 12,
      name: 'December',
      days: 31,
      active_days: [],
      lines: { linkedin: { activity_elements: {} }, gmail: { activity_elements: {} }, team: { activity_elements: {} }, salesforce: { activity_elements: {} } }
    }
  ],
  note_for_id: null,
  all_notes: {
    notes: [],
    customer: null
  },
  isDateFilter: false,
  addContactPerson: false,
  citrix_team: {
    gmail: [
      {
        id: 1,
        subject: 'Your subscription will end soon',
        body: 'Dear Alex,\nI trust you are doing well.\nWe are delighted to have you as our customer for almost a year. Today, I have noticed that your subscription with us is soon coming to an end. Because of this I thought to reach out to you in order to hear any of your concerns or questions that you may have related using our product. I would be pleased to hear your feedback.\nIf you are considering a renewal, I am more than happy to assist you. Please let me know.\nKind regards,\nDaniel R.\n\n___',
        sender: 911,
        sent: '2021-06-11T15:24:25Z',
        replies: []
      },
      {
        id: 2,
        subject: 'Reminder of expired subscription',
        body: 'Dear Alex,\nI’m hoping you are doing great.\nI am writing as your subscription has expired today. I would like to remind you that keeping your discounted price would require you to renew your subscription by the end of today.\nWe are committed to keeping our customers satisfied. If you would like to schedule a call with me in order to discuss any details, I am more than happy to do so.\nKindly waiting for your response.\nBest,\nDaniel R.\n\n___',
        sender: 911,
        sent: '2021-06-12T15:20:25Z',
        replies: []
      },
      {
        id: 3,
        subject: 'Reminder of expired subscription',
        body: 'Dear Alex,\nI hope you’re doing well!\nI wanted to reach out to you one more time as I haven’t heard back from you since my last email. It looks like your subscription expired for seven days and there was no renewal requested by you.\nWe would like to know if there is anything we could help you with. If you need technical support, you can always reach out to me.\nKind regards,\nDaniel R.\n',
        sender: 911,
        sent: '2021-07-03T12:20:25Z',
        replies: []
      }
    ]
  },
  docusign_team: {
    gmail: [
      {
        id: 4,
        subject: 'Your subscription will end soon',
        body: 'Dear Kate,\nI trust you are doing well.\nWe have been delighted to have you as our customer for almost a year now. \nI am reaching out today as your subscription is getting closer to its expiration. I would like to hear any of your feedback and if you have any questions regarding your renewal.\nI am more than happy to assist you. Please let me know how I can support you.\nBest regards, \nMaria S.',
        sender: 811,
        sent: '2021-02-15T10:24:25Z',
        replies: []
      },
      {
        id: 5,
        subject: 'Reply - Your subscription will end soon',
        body: 'Hi Kate, \nThank you for your quick response and sending your questions.\nYes, you can easily automatize the advanced calendar by selecting the option ‘New Boards’ from the list after choosing ‘Automatization’ from the menu. \nYou can follow this link to see the tutorial for this step on our webpage: \nwww.monday.com/search?q=project+management+tools&source= \nIf you have any more concerns please let me know and I can connect your with our technical support team.\nBest, \nMaria',
        sender: 811,
        sent: '2021-02-16T12:24:25Z',
        replies: []
      },
      {
        id: 6,
        subject: 'Reply - Your subscription will end soon',
        body: 'Hi Kate, \nI’m glad to hear I could help you with that. \nPlease, if you have any further concerns related to the renewal let me know. I’m more than happy to assist you before you proceed.\nThank you and wish you a great day, \nMaria',
        sender: 811,
        sent: '2021-02-17T15:24:25Z',
        replies: []
      }
    ]
  },
  team_activity: {
    gmail: [
      {
        id: 11,
        subject: 'Quick question about renewals',
        body: 'Hi David,\nTrust you are doing well today. Did you have a chance to review our offer?\nPlease let me know if you have any concerns, questions. I’ll be happy to assist you.\nBest regards, \nLarry',
        sender: 111,
        sent: '2022-01-17T15:24:25Z',
        replies: [
          {
            id: 12,
            subject: '',
            body: 'Hey Larry,\nThanks for clarifying my questions on renewals. I’ll have a chance to review your offer later today. Will get back to you soon!\nCheers, \nDavid\n___',
            sender: 2,
            sent: '2022-01-18T16:24:25Z',
            replies: []
          }
        ]
      },
      {
        id: 21,
        subject: '',
        body: 'Hi Lucas, \nThank you for the details. Our team is on solving your problem and will get back to you soon!\nWish you a great day, \nMaria',
        sender: 222,
        sent: '2022-01-19T12:24:25Z',
        replies: []
      }
    ],
    linkedin: {
      comments: [{
        author: 333,
        created: '2022-01-13T01:10:15Z',
        id: 333,
        post: 5555,
        retrieved: '2022-01-13T01:10:16Z',
        text: 'I think that’s a genius idea! I’m waiting for your next article to be published!'
      }],
      likes: [{
        author: 333,
        created: '2022-01-10T15:24:25Z',
        id: 444,
        post: 5555,
        retrieved: '2022-01-10T15:24:26Z'
      }, {
        author: 222,
        created: '2022-01-11T11:24:25Z',
        id: 555,
        post: 6666,
        retrieved: '2022-01-11T11:24:26Z'
      }],
      posts: [{
        author: 444,
        created: '2022-01-10T17:24:26Z',
        id: 333,
        image: require('../assets/test/post.jpg'),
        retrieved: '2022-01-10T17:24:27Z',
        text: 'Tech can be as good as the purpose you use it for and the system you embedded it into.\n#analytics #data #diversityandinclusion',
        title: ''
      }],
      shares: [{
        author: 444,
        created: '2022-01-11T11:25:25Z',
        id: 555,
        post: 7777,
        retrieved: '2022-01-11T11:25:26Z'
      }]
    }
  },
  citrix_salesforce: [{
    id: 1,
    year: 2020,
    month: 'MARCH',
    text: 'Became Lead',
    created: '2020-03-29T11:25:26Z'
  }, {
    id: 2,
    year: 2020,
    month: 'APRIL',
    text: 'Became Opportunity',
    created: '2020-04-29T11:25:26Z'
  }, {
    id: 3,
    year: 2020,
    month: 'JUNE',
    text: 'Closed Deal',
    created: '2020-06-20T11:25:26Z'
  }],
  docusign_salesforce: [{
    id: 7,
    year: 2020,
    month: 'FEB',
    text: 'Became Lead',
    created: '2020-02-07T11:25:26Z'
  }, {
    id: 8,
    year: 2020,
    month: 'FEB',
    text: 'Became Opportunity',
    created: '2020-02-08T11:25:26Z'
  }, {
    id: 9,
    year: 2020,
    month: 'MARCH',
    text: 'Closed Deal',
    created: '2020-03-09T11:25:26Z'
  }, {
    id: 10,
    year: 2021,
    month: 'MARCH',
    text: 'Renewal',
    created: '2021-03-10T11:25:26Z'
  }],
  salesforce: [{
    id: 4,
    year: 2022,
    month: 'MARCH',
    text: 'Became Lead',
    created: '2022-03-29T11:25:26Z'
  }, {
    id: 5,
    year: 2022,
    month: 'APRIL',
    text: 'Became Opportunity',
    created: '2022-04-29T11:25:26Z'
  }, {
    id: 6,
    year: 2022,
    month: 'JUNE',
    text: 'Closed Deal',
    created: '2022-06-20T11:25:26Z'
  }]
}

const getters = {
  customers (state) {
    return state.customers
  },
  api_customers (state) {
    return state.api_customers
  },
  activity_customer_id (state) {
    return state.activity_customer_id
  },
  activity_customer (state) {
    return state.activity_customer
  },
  activity_showing_messages (state) {
    return state.activity_showing_messages
  },
  activity_keywords_filter (state) {
    return state.activity_keywords_filter
  },
  months (state) {
    return state.months
  },
  config (state) {
    return state.config
  },
  user_auth_token (state) {
    return state.user_auth_token
  },
  active_line (state) {
    return state.active_line
  },
  active_point (state) {
    return state.active_point
  },
  active_type (state) {
    return state.active_type
  },
  note_for_id (state) {
    return state.note_for_id
  },
  all_notes (state) {
    return state.all_notes
  },
  active_date_interval (state) {
    return state.active_date_interval
  },
  isDateFilter (state) {
    return state.isDateFilter
  },
  addContactPerson (state) {
    return state.addContactPerson
  },
  activity_scroll_to (state) {
    return state.activity_scroll_to
  },
  activity_seach_query (state) {
    return state.activity_seach_query
  },
  // for test
  user_photo (state) {
    return state.user_photo
  },
  team_activity (state) {
    return state.team_activity
  },
  salesforce (state) {
    return state.salesforce
  }
}

const actions = {
  [AUTH_CUSTOMER] ({ commit }, token) {
    commit(SET_AUTH_TOKEN, token)
  },
  [SIGN_IN_CUSTOMER] ({ commit }, params) {
    return AuthService.getToken(params).then(
      ({ data }) => {
        commit(SET_AUTH_TOKEN, data.token)
      }
    )
  },
  [SIGN_UP_CUSTOMER] ({ commit }, params) {
    return AuthService.signup(params).then(
      ({ data }) => {
        commit(SET_AUTH_TOKEN, data.token)
      }
    )
  },
  [UPDATE_ACTIVITY_SEARCH_QUERY] ({ state }, value) {
    state.activity_seach_query = value
  },
  [UPDATE_ACTIVE_TYPE] ({ state }, params) {
    state.active_type = params
  },
  [SET_ADD_NEW_CONTACT_PERSON] ({ state }, value) {
    state.addContactPerson = value
  },
  [ADD_NEW_CONTACT_PERSON] ({ _ }, value) {
    return CustomerService.addNewContactPerson(value.id, value.person)
  },
  [UPDATE_NOTE_TARGET] ({ commit }, id) {
    commit(SET_NOTE_TARGET, id)
  },
  [SHOW_NOTES] ({ commit }, params) {
    commit(SET_SHOW_NOTES, params)
  },
  [FETCH_NOTES] ({ _ }, params) {
    return CustomerService.fetchNotes(params)
  },
  [ADD_NOTE] ({ _ }, params) {
    return CustomerService.addNote(params)
  },
  [ACTIVITY_SEARCH] ({ state, commit }, params) {
    commit(SET_ACTIVITY_PRESETS)
    CustomerService.activitySearch(state.activity_customer_id, params).then(({ data }) => {
      // test data
      let testDataTeam = {}
      let testDataSalesforce = []
      switch (state.activity_customer_id) {
        case 9:
          testDataTeam = state.citrix_team
          testDataSalesforce = state.citrix_salesforce
          break
        case 8:
          testDataTeam = state.docusign_team
          testDataSalesforce = state.docusign_salesforce
          break
        default:
          testDataTeam = state.team_activity
          testDataSalesforce = state.salesforce
          commit(UPDATE_CUSTOMER_LINE, { list: 'team', nested_type: 'linkedin', kind: 'comment', data: testDataTeam.linkedin.comments })
          commit(UPDATE_CUSTOMER_LINE, { list: 'team', nested_type: 'linkedin', kind: 'like', data: testDataTeam.linkedin.likes })
          commit(UPDATE_CUSTOMER_LINE, { list: 'team', nested_type: 'linkedin', kind: 'post', data: testDataTeam.linkedin.posts })
          commit(UPDATE_CUSTOMER_LINE, { list: 'team', nested_type: 'linkedin', kind: 'share', data: testDataTeam.linkedin.shares })
          break
      }
      commit(UPDATE_CUSTOMER_LINE, { list: 'team', nested_type: 'gmail', kind: 'gmail', data: testDataTeam.gmail })
      // commit(UPDATE_CUSTOMER_LINE, { list: 'salesforce', nested_type: 'salesforce', kind: 'salesforce', data: testDataSalesforce })
      console.log(testDataSalesforce)
      // test data ^

      commit(UPDATE_CUSTOMER_LINE, { list: 'linkedin', nested_type: 'linkedin', kind: 'comment', data: data.linkedin.comments })
      commit(UPDATE_CUSTOMER_LINE, { list: 'linkedin', nested_type: 'linkedin', kind: 'like', data: data.linkedin.likes })
      commit(UPDATE_CUSTOMER_LINE, { list: 'linkedin', nested_type: 'linkedin', kind: 'post', data: data.linkedin.posts })
      commit(UPDATE_CUSTOMER_LINE, { list: 'gmail', nested_type: 'gmail', kind: 'gmail', data: data.gmail })
    })
    if (state.activity_customer.id === 9) {
      CustomerService.fetchSalesforceContacts().then(({ data }) => {
        commit(UPDATE_CUSTOMER_LINE, { list: 'salesforce', nested_type: 'Contact', kind: 'salesforce', data: data })
      })
      CustomerService.fetchSalesforceAccounts().then(({ data }) => {
        commit(UPDATE_CUSTOMER_LINE, { list: 'salesforce', nested_type: 'Account', kind: 'salesforce', data: data })
      })
      CustomerService.fetchSalesforceLeads().then(({ data }) => {
        commit(UPDATE_CUSTOMER_LINE, { list: 'salesforce', nested_type: 'Lead', kind: 'salesforce', data: data })
      })
      CustomerService.fetchSalesforceOpportunitys().then(({ data }) => {
        commit(UPDATE_CUSTOMER_LINE, { list: 'salesforce', nested_type: 'Opportunity', kind: 'salesforce', data: data })
      })
    }
  },
  [UPDATE_DATE_INTERVAL] ({ state }, params) {
    state.active_date_interval = params
  },
  [UPDATE_ACTIVE_LINE] ({ state }, param) {
    state.active_line = param
  },
  [TOGGLE_ACTIVITY_DATE_FILTER] ({ state }) {
    state.isDateFilter = !state.isDateFilter
  },
  [UPDATE_ACTIVE_POINT] ({ state }, param) {
    if (state.active_point === param) {
      state.active_point = ''
    } else {
      state.active_point = param
    }
  },
  [CLEAR_CUSTOMER_TOKEN] ({ commit }) {
    commit(SET_AUTH_TOKEN, '')
  },
  [UPDATE_ACTIVITY_CUSTOMER] ({ commit, state }, id) {
    state.active_type = {
      kind: 'days',
      month_id: null,
      date_range: '14_day'
    }

    commit(SET_ACTIVITY_KEYWORDS_FILTER, { keywordsInclude: [], keywordsExclude: [] })
    commit(SET_ACTIVITY_CUSTOMER, id)
  },
  [UPDATE_ACTIVITY_SHOWING_MESSAGES] ({ commit }, list) {
    commit(SET_ACTIVITY_SHOWING_MESSAGES, list)
  },
  [FETCH_CUSTOMERS] ({ commit, state }, params) {
    CustomerService.fetchCustomersList().then(
      ({ data }) => {
        commit(SET_CUSTOMERS, data)
        commit(SET_ACTIVITY_CUSTOMER, data[0].id)
        return true
      }
    )
  },
  [FETCH_SINGLE_POST] ({ _ }, id) {
    return CustomerService.fetchSinglePost(id)
  },
  [FETCH_CUSTOMER_ACTIVITY] ({ commit, state }, params) {
    commit(SET_ACTIVITY_PRESETS)

    CustomerService.fetchCustomerActivity(state.activity_customer.id, state.active_date_interval).then(({ data }) => {
      // test data
      let testDataTeam = {}
      let testDataSalesforce = []
      switch (state.activity_customer_id) {
        case 9:
          testDataTeam = state.citrix_team
          testDataSalesforce = state.citrix_salesforce
          break
        case 8:
          testDataTeam = state.docusign_team
          testDataSalesforce = state.docusign_salesforce
          break
        default:
          testDataTeam = state.team_activity
          testDataSalesforce = state.salesforce
          commit(UPDATE_CUSTOMER_LINE, { list: 'team', nested_type: 'linkedin', kind: 'comment', data: testDataTeam.linkedin.comments })
          commit(UPDATE_CUSTOMER_LINE, { list: 'team', nested_type: 'linkedin', kind: 'like', data: testDataTeam.linkedin.likes })
          commit(UPDATE_CUSTOMER_LINE, { list: 'team', nested_type: 'linkedin', kind: 'post', data: testDataTeam.linkedin.posts })
          commit(UPDATE_CUSTOMER_LINE, { list: 'team', nested_type: 'linkedin', kind: 'share', data: testDataTeam.linkedin.shares })
          break
      }
      commit(UPDATE_CUSTOMER_LINE, { list: 'team', nested_type: 'gmail', kind: 'gmail', data: testDataTeam.gmail })
      console.log(testDataSalesforce)
      // commit(UPDATE_CUSTOMER_LINE, { list: 'salesforce', nested_type: 'salesforce', kind: 'salesforce', data: testDataSalesforce })
      // test data ^

      commit(UPDATE_CUSTOMER_LINE, { list: 'linkedin', nested_type: 'linkedin', kind: 'comment', data: data.linkedin.comments })
      commit(UPDATE_CUSTOMER_LINE, { list: 'linkedin', nested_type: 'linkedin', kind: 'like', data: data.linkedin.likes })
      commit(UPDATE_CUSTOMER_LINE, { list: 'linkedin', nested_type: 'linkedin', kind: 'post', data: data.linkedin.posts })

      commit(UPDATE_CUSTOMER_LINE, { list: 'gmail', nested_type: 'gmail', kind: 'gmail', data: data.gmail })
    })

    if (state.activity_customer.id === 9) {
      CustomerService.fetchSalesforceContacts().then(({ data }) => {
        commit(UPDATE_CUSTOMER_LINE, { list: 'salesforce', nested_type: 'Contact', kind: 'salesforce', data: data })
      })
      CustomerService.fetchSalesforceAccounts().then(({ data }) => {
        commit(UPDATE_CUSTOMER_LINE, { list: 'salesforce', nested_type: 'Account', kind: 'salesforce', data: data })
      })
      CustomerService.fetchSalesforceLeads().then(({ data }) => {
        commit(UPDATE_CUSTOMER_LINE, { list: 'salesforce', nested_type: 'Lead', kind: 'salesforce', data: data })
      })
      CustomerService.fetchSalesforceOpportunitys().then(({ data }) => {
        commit(UPDATE_CUSTOMER_LINE, { list: 'salesforce', nested_type: 'Opportunity', kind: 'salesforce', data: data })
      })
    }
    CustomerService.fetchContacts(state.activity_customer.id).then(
      ({ data }) => {
        commit(SET_CUSTOMER_CONTACTS, { id: state.activity_customer.id, contacts: data })
      }
    )
  },
  [FETCH_CUSTOMER_CONTACTS] ({ commit }, id) {
    CustomerService.fetchContacts(id).then(
      ({ data }) => {
        commit(SET_CUSTOMER_CONTACTS, { id: id, contacts: data })
      }
    )
  }
}

const mutations = {
  [SET_CUSTOMER_CONTACTS] (state, data) {
    const customer = state.api_customers.filter(cus => { return cus.id === data.id })[0]
    if (!customer.contacts || !customer.contacts.length) {
      customer.contacts = data.contacts
    }
  },
  [SET_ACTIVITY_CUSTOMER] (state, data) {
    state.activity_customer_id = data
    state.activity_customer = state.api_customers.filter(cus => { return cus.id === data })[0]
  },
  [SET_NOTE_TARGET] (state, id) {
    state.note_for_id = id
  },
  [SET_SHOW_NOTES] (state, obj) {
    state.all_notes = obj
  },
  [UPDATE_CUSTOMER_LINE] (state, params) {
    // we should see only antities who has ANY word from keywordsInclude, so, remove all entities who doesn't have any of the words to include.
    function hasAnyIncludeWords (text) {
      const lowercaseText = text.toLowerCase()
      for (const word of state.activity_keywords_filter.keywordsInclude) {
        if (lowercaseText.includes(word.toLowerCase())) {
          return true
        }
      }

      return false
    }
    // after we left only required entities by the Include filter - we remove from the everything that contains ANY word from exclusion
    function hasNoExcludeWords (text) {
      const lowercaseText = text.toLowerCase()
      for (const word of state.activity_keywords_filter.keywordsExclude) {
        if (lowercaseText.includes(word.toLowerCase())) {
          return false
        }
      }

      return true
    }

    // leave only records that matches our keywords filter
    if (state.activity_keywords_filter.keywordsInclude.length > 0 || state.activity_keywords_filter.keywordsExclude.length > 0) {
      // we can filter only specific entity kinds
      const typeOfEntity = `${params.nested_type}_${params.kind}`
      const entityKindsWeCantFilter = ['linkedin_like', 'linkedin_share']
      if (!entityKindsWeCantFilter.includes(typeOfEntity)) {
        const entityFieldNames = []
        if (typeOfEntity === 'gmail_gmail') {
          entityFieldNames.push('subject', 'body') // || entity.replies recursively
        } else if (typeOfEntity === 'Account_salesforce') {
          entityFieldNames.push('name', 'owner')
        } else if (typeOfEntity === 'Contact_salesforce' || typeOfEntity === 'Opportunity_salesforce') {
          entityFieldNames.push('account_name', 'name')
        } else if (typeOfEntity === 'Lead_salesforce') {
          entityFieldNames.push('title', 'name')
        } else {
          entityFieldNames.push('text')
        }

        if (state.activity_keywords_filter.keywordsInclude.length > 0) {
          params.data = params.data.filter(entity => {
            for (const fieldName of entityFieldNames) {
              if (hasAnyIncludeWords(entity[fieldName] || '')) {
                return true
              }
            }
            return false
          })
        }
        if (state.activity_keywords_filter.keywordsExclude.length > 0) {
          params.data = params.data.filter(entity => {
            for (const fieldName of entityFieldNames) {
              if (!hasNoExcludeWords(entity[fieldName] || '')) {
                return false
              }
            }
            return true
          })
        }
      }
    }

    // Set info in month
    // console.log('HERE ___!', params)

    params.data.forEach((element) => {
      element.item_type = params.kind
      element.source_type = params.list
      element.nested_type = params.nested_type
    })
    const customerMonths = JSON.parse(JSON.stringify(state.months))
    customerMonths.forEach(month => {
      // All activity current type for month
      const activityElements = params.data.filter(msg => {
        // if (params.list === 'salesforce') {
        //   return new Date(msg.close_date).getMonth() + 1 === month.id
        // } else {
        if (msg.created === undefined) {
          return new Date(msg.sent).getMonth() + 1 === month.id
        } else {
          return new Date(msg.created).getMonth() + 1 === month.id
        }
        // }
      })

      const yearsDays = {}
      activityElements.map(element => {
        let date = ''
        // if (params.list === 'salesforce') {
        //   date = new Date(element.close_date)
        // } else {
        if (element.created === undefined) {
          date = new Date(element.sent)
        } else {
          date = new Date(element.created)
        }
        // }

        if (!yearsDays[date.getFullYear()]) {
          yearsDays[date.getFullYear()] = []
        }
        yearsDays[date.getFullYear()].push(date.getDate())

        return true
      })
      if (!month.active_years_days) {
        month.active_years_days = {}
      }
      for (const year in yearsDays) {
        const daysList = yearsDays[year].filter((obj, idx, arr) => { return arr.indexOf(obj) === idx })
        daysList.sort(function (a, b) { return b - a })
        month.active_years_days[year] = daysList
      }
      for (const year in month.active_years_days) {
        month.lines[params.list].activity_elements[year] = activityElements.filter(elem => {
          let date = ''
          // if (params.list === 'salesforce') {
          //   date = new Date(elem.close_date)
          // } else {
          if (elem.created === undefined) {
            date = new Date(elem.sent)
          } else {
            date = new Date(elem.created)
          }
          // }
          return date.getFullYear().toString() === year
        })
      }
      // month.lines[params.list] = { activity_elements: activityElements }

      if (activityElements.length > 0) {
        /** DEL! */
        const days = activityElements.map(element => {
          // if (params.list === 'salesforce') {
          //   return new Date(element.close_date).getDate()
          // } else {
          if (element.created === undefined) {
            return new Date(element.sent).getDate()
          } else {
            return new Date(element.created).getDate()
          }
          // }
        })
        // Select uniq day numbers.
        month.active_days = days.filter((day, idx, arr) => { return arr.indexOf(day) === idx })
        month.active_days.sort(function (a, b) { return b - a })

        const years = activityElements.map(element => {
          // if (params.list === 'salesforce') {
          //   return new Date(element.close_date).getFullYear()
          // } else {
          if (element.created === undefined) {
            return new Date(element.sent).getFullYear()
          } else {
            return new Date(element.created).getFullYear()
          }
          // }
        })
        // Select uniq day numbers.
        month.active_years = years.filter((year, idx, arr) => { return arr.indexOf(year) === idx })
        month.active_years.sort(function (a, b) { return b - a }) // ???
      }
      /** ^----- DEL -----^ */
    })

    // Get active months
    const activeMonths = customerMonths.filter(m => {
      return !!Object.keys(m.lines[params.list].activity_elements).length
    })

    activeMonths.forEach(actMonth => {
      const presentingMonth = state.activity_customer.active_months.find(element => { return element.id === actMonth.id })
      if (presentingMonth) {
        for (const year in actMonth.lines[params.list].activity_elements) {
          if (!presentingMonth.lines[params.list].activity_elements[year]) {
            presentingMonth.lines[params.list].activity_elements[year] = []
          }
          actMonth.lines[params.list].activity_elements[year].forEach(activ => {
            presentingMonth.lines[params.list].activity_elements[year].push(activ)
          })
          if (!presentingMonth.active_years_days[year]) {
            presentingMonth.active_years_days[year] = [...actMonth.active_years_days[year]]
          }
          const daysList = [...presentingMonth.active_years_days[year], ...actMonth.active_years_days[year]]

          presentingMonth.active_years_days[year] = daysList.filter((day, idx, arr) => { return arr.indexOf(day) === idx })
          presentingMonth.active_years_days[year].sort(function (a, b) { return b - a })
        }
        // actMonth.lines[params.list].activity_elements.forEach(activ => {
        //   presentingMonth.lines[params.list].activity_elements.push(activ)
        // })
        const daysList = [...presentingMonth.active_days, ...actMonth.active_days]
        // Select uniq day numbers.
        presentingMonth.active_days = daysList.filter((day, idx, arr) => { return arr.indexOf(day) === idx })
        presentingMonth.active_days.sort(function (a, b) { return b - a })
      } else {
        state.activity_customer.active_months.push(actMonth)
      }
      state.activity_customer.active_months.sort(function (a, b) { return b.id - a.id })

      const years = state.activity_customer.active_years.concat(actMonth.active_years)
      state.activity_customer.active_years = years.filter((year, idx, arr) => { return arr.indexOf(year) === idx })
      state.activity_customer.active_years.sort(function (a, b) { return b - a })
    })

    // Other
    state.activity_customer.active_months.forEach((month, index) => {
      month.current_position = index

      for (const preset in Object.keys(state.config)) {
        const presetName = Object.keys(state.config)[preset]

        for (const year in month.lines[params.list].activity_elements) {
          // month
          //
          const daysDiv = parseInt(month.days / state.config[presetName].month_points)
          const pointDays = daysDiv + 0.5 < (month.days / state.config[presetName].month_points) ? daysDiv + 1 : daysDiv

          for (let iter = 0; iter < state.config[presetName].month_points; iter++) {
            if (month.lines[params.list].activity_elements) {
              const start = (iter * pointDays) + 1
              const end = iter + 1 === state.config[presetName].month_points ? month.days : (iter + 1) * pointDays

              const elems = month.lines[params.list].activity_elements[year].filter(elem => {
                let day = ''
                // if (params.list === 'salesforce') {
                //   day = new Date(elem.close_date).getDate()
                // } else {
                if (elem.created === undefined) {
                  day = new Date(elem.sent).getDate()
                } else {
                  day = new Date(elem.created).getDate()
                }
                // }
                return day >= start && day <= end
              })

              if (!state.activity_customer.display_data.months[presetName][month.id][params.list]) {
                state.activity_customer.display_data.months[presetName][month.id][params.list] = {}
              }
              if (!state.activity_customer.display_data.months[presetName][month.id][params.list][iter]) {
                state.activity_customer.display_data.months[presetName][month.id][params.list][iter] = {}
              }
              state.activity_customer.display_data.months[presetName][month.id][params.list][iter][year] = elems

              if (!state.activity_customer.display_data.month_styles[presetName][iter].list[month.id]) {
                state.activity_customer.display_data.month_styles[presetName][iter].list[month.id] = {}
              }
              if (!state.activity_customer.display_data.month_styles[presetName][iter].list[month.id][params.list]) {
                state.activity_customer.display_data.month_styles[presetName][iter].list[month.id][params.list] = {}
              }
              if (!state.activity_customer.display_data.month_styles[presetName][iter].list[month.id][params.list][year]) {
                state.activity_customer.display_data.month_styles[presetName][iter].list[month.id][params.list][year] = { top: null }
              }
              const tmp = state.activity_customer.display_data.month_styles[presetName][iter].height.split('px')[0]
              const height = Math.floor(Math.random() * (tmp - 30))

              state.activity_customer.display_data.month_styles[presetName][iter].list[month.id][params.list][year].top = `${height}px`
            }
          }

          // day
          const seconds = 24 * 60 * 60
          const pointHours = parseInt(seconds / state.config[presetName].day_points)

          for (let iter = 0; iter < state.config[presetName].day_points; iter++) {
            const start = iter === 0 ? 0 : (iter * pointHours) + 1
            const end = iter + 1 === state.config[presetName].day_points ? seconds : (iter + 1) * pointHours

            month.active_days.forEach(day => {
              if (month.lines[params.list].activity_elements) {
                const elems = month.lines[params.list].activity_elements[year].filter(elem => {
                  let elemDate = ''
                  // if (params.list === 'salesforce') {
                  //   elemDate = new Date(elem.close_date)
                  // } else {
                  if (elem.created === undefined) {
                    elemDate = new Date(elem.sent)
                  } else {
                    elemDate = new Date(elem.created)
                  }
                  // }

                  const elemDay = elemDate.getDate()
                  if (elemDay !== day) {
                    return false
                  }
                  const elemTime = elemDate
                  const elemSec = (elemTime.getHours() * 60 * 60) + (elemTime.getMinutes() * 60) + elemTime.getSeconds()
                  return elemSec >= start && elemSec <= end
                })
                if (!state.activity_customer.display_data.days[presetName][month.id][day]) {
                  state.activity_customer.display_data.days[presetName][month.id][day] = {}
                }
                if (!state.activity_customer.display_data.days[presetName][month.id][day][params.list]) {
                  state.activity_customer.display_data.days[presetName][month.id][day][params.list] = {}
                }
                if (!state.activity_customer.display_data.days[presetName][month.id][day][params.list][iter]) {
                  state.activity_customer.display_data.days[presetName][month.id][day][params.list][iter] = {}
                }
                state.activity_customer.display_data.days[presetName][month.id][day][params.list][iter][year] = elems

                if (!state.activity_customer.display_data.day_styles[presetName][iter].list[month.id]) {
                  state.activity_customer.display_data.day_styles[presetName][iter].list[month.id] = {}
                }
                if (!state.activity_customer.display_data.day_styles[presetName][iter].list[month.id][day]) {
                  state.activity_customer.display_data.day_styles[presetName][iter].list[month.id][day] = {}
                }
                if (!state.activity_customer.display_data.day_styles[presetName][iter].list[month.id][day][params.list]) {
                  state.activity_customer.display_data.day_styles[presetName][iter].list[month.id][day][params.list] = {}
                }
                if (!state.activity_customer.display_data.day_styles[presetName][iter].list[month.id][day][params.list][year]) {
                  state.activity_customer.display_data.day_styles[presetName][iter].list[month.id][day][params.list][year] = { top: null }
                }
                const tmp = state.activity_customer.display_data.day_styles[presetName][iter].height.split('px')[0]
                const height = Math.floor(Math.random() * (tmp - 30))

                state.activity_customer.display_data.day_styles[presetName][iter].list[month.id][day][params.list][year].top = `${height}px`
              }
            })
          }
        }
      }
      // ---
    })

    if (params.kind === 'like') {
      state.activity_customer.ready = true
    }
  },
  [SET_ACTIVITY_PRESETS] (state) {
    // console.log('for pers+')
    // For styles
    const monthHeigth = {}
    const dayHeigth = {}
    for (const preset in Object.keys(state.config)) {
      const presetName = Object.keys(state.config)[preset]

      let itemH = parseInt(state.config[presetName].month_height_px / state.config[presetName].month_points)
      monthHeigth[presetName] = {}
      for (let iter = 0; iter < state.config[presetName].month_points; iter++) {
        // console.log('YYYm - ', presetName, iter)
        const h = (iter + 1 === state.config[presetName].month_points) ? `${state.config[presetName].month_height_px - (itemH * iter)}px` : `${itemH}px`
        monthHeigth[presetName][iter] = { height: h, list: [] }
      }

      itemH = parseInt(state.config[presetName].day_height_px / state.config[presetName].day_points)
      dayHeigth[presetName] = {}
      for (let iter = 0; iter < state.config[presetName].day_points; iter++) {
        const h = (iter + 1 === state.config[presetName].day_points) ? `${state.config[presetName].day_height_px - (itemH * iter)}px` : `${itemH}px`
        dayHeigth[presetName][iter] = { height: h, list: [] }
      }
    }
    // let itemH = parseInt(state.config.month_height_px / state.config.month_points)
    // for (let iter = 0; iter < state.config.month_points; iter++) {
    //   const h = (iter + 1 === state.config.month_points) ? `${state.config.month_height_px - (itemH * iter)}px` : `${itemH}px`
    //   monthHeigth[iter] = { height: h, list: [] }
    // }

    // For data
    state.activity_customer.active_months = []
    state.activity_customer.display_data = {
      month_styles: monthHeigth,
      day_styles: dayHeigth,
      months: {},
      days: {}
    }
    state.activity_customer.active_years = []
    for (const preset in Object.keys(state.config)) {
      const presetName = Object.keys(state.config)[preset]
      state.activity_customer.display_data.months[presetName] = {}
      state.activity_customer.display_data.days[presetName] = {}
      for (let iter = 1; iter <= 12; iter++) {
        state.activity_customer.display_data.months[presetName][iter] = {}
        state.activity_customer.display_data.days[presetName][iter] = {}
      }
    }
    // console.log('for pers-')
  },
  [SET_ACTIVITY_SHOWING_MESSAGES] (state, data) {
    state.activity_showing_messages = data
    state.active_line = data.kind
  },
  [SET_ACTIVITY_KEYWORDS_FILTER] (state, data) {
    state.activity_keywords_filter.keywordsInclude = data.keywordsInclude
    state.activity_keywords_filter.keywordsExclude = data.keywordsExclude
  },
  [SET_CUSTOMERS] (state, data) {
    state.api_customers = data.sort(function (a, b) { return a.id - b.id })
    if (state.activity_customer_id === null && state.api_customers.length > 0) {
      state.activity_customer_id = state.api_customers[0].id
    }
  },
  [SET_AUTH_TOKEN] (state, token) {
    state.user_auth_token = token.length > 0
    localStorage.setItem('token', token)
    ApiService.setToken(token)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
